import React from "react";
import styled, { css } from "styled-components";

const SpacerItem = styled.div`
  width: 100%;
  height: ${(props) => props.height};
  @media (max-width: 991px) {
    height: ${(props) => props.tabletHeight};
  }
  @media (max-width: 767px) {
    height: ${(props) => props.mobileHeight};
  }
`;

function Spacer(props) {
  return (
    <SpacerItem
      height={props.height}
      tabletHeight={props.tabletHeight ? props.tabletHeight : props.height}
      mobileHeight={props.mobileHeight ? props.mobileHeight : props.height}
    />
  );
}

export default Spacer;
