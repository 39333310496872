import React from "react";
import styled from "styled-components";

import WhatWeDoItem from "./whatWeDoItem";
import { getFluidGatsbyImage } from "../utils/gatsby-storyblok-image-fixed";

const WhatWeDoContainerOuter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #2c3445;
  align-items: center;
  overflow: hidden;
  max-width: 100vw;
`;

const WhatWeDoContainer = styled.div`
  width: 100%;
  padding-top: 125px;
  margin: 0 40px;
  padding-bottom: 50px;
  background-color: #2c3445;
  max-width: 1100px;
  @media (max-width: 768px) {
    width: 90%;
    padding-top: 50px;
    padding-bottom: 20px;
  }
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 80px;
  line-height: 80px;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin-bottom: 70px;
  @media (max-width: 1100px) {
    font-size: 60px;
    line-height: 60px;
  }
  @media (max-width: 768px) {
    font-size: 44px;
    line-height: 44px;
    margin-bottom: 25px;
  }
`;

const WhatWeDoItemsContainer = styled.div`
  margin-bottom: 75px;
`;

function WhatWeDo({ title, sections }) {
  return (
    <WhatWeDoContainerOuter>
      <WhatWeDoContainer>
        <Title>{title}</Title>
        <WhatWeDoItemsContainer>
          {sections.map((section, i) => {
            let caseStudyImage = getFluidGatsbyImage(section.caseStudyImage, {
              maxWidth: 1000,
            });

            return (
              <WhatWeDoItem
                title={section.title}
                paragraph={section.paragraph}
                caseStudyImage={caseStudyImage}
                caseStudySubtext={section.caseStudySubtext}
                caseStudyTitle={section.caseStudyTitle}
                ctaButtonText={
                  section.caseStudyCtaText && section.caseStudyCtaText
                }
                ctaButtonLink={
                  section.caseStudyCtaLink && section.caseStudyCtaLink
                }
                enableCta={section.enableCta}
                reverse={section.reversedMode}
              />
            );
          })}
        </WhatWeDoItemsContainer>
      </WhatWeDoContainer>
    </WhatWeDoContainerOuter>
  );
}

export default WhatWeDo;
