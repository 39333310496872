import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import Skeleton from "react-loading-skeleton";
import useInView from "react-cool-inview";
import Moment from "react-moment";
import { Parallax } from "react-scroll-parallax";

import LazyImage from "./lazy-image";

import HeaderImage from "../assets/images/altHeaderImage.jpg";

const HeaderContainerOuter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const HeaderContainer = styled(motion.div)`
  width: 100%;
  flex-direction: column;
  min-height: 400px;
  top: 0;
  left: 0;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: -100px;
  padding: 70px 50px 100px 50px;
  overflow: hidden;
  margin-bottom: 75px;
  @media (max-width: 1000px) {
    padding: 70px 50px 100px 50px;
  }
  @media (max-width: 786px) {
    padding: 70px 20px 100px 20px;
  }
`;

const BackgroundAnimLayer = styled(motion.div)`
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  background-color: #2c3445;
`;

const BackgroundLayer = styled(motion.div)`
  background-image: url(${(props) => props.image});
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-color: #2c3445;
  height: 100%;
  width: 100%;
`;

const HeaderContent = styled.div`
  width: 100%;
  max-width: 918px;
  z-index: 2;
  @media (max-width: 768px) {
    max-width: unset;
  }
`;

const Title = styled(motion.div)`
  font-size: 51px;
  line-height: 61px;
  color: #ffffff;
  font-weight: 600;
  @media (max-width: 768px) {
    font-size: 36px;
    line-height: 41px;
  }
`;

const Metadata = styled(motion.div)`
  color: #fff;
  font-size: 13px;
`;

const Author = styled(motion.div)`
  margin-right: 5px;
  margin-bottom: 7px;
  line-height: 0;
  margin-top: 20px;
  letter-spacing: 1px;
`;

const DateWritten = styled(motion.div)``;

const HeaderImageContainer = styled(motion.div)`
  z-index: 3;
  margin-bottom: 50px;
  width: 918px;
  max-height: 575px;
  height: 100%;
  margin-top: -150px;
  background-color: #f5f5f5;
  overflow: hidden;
  @media (max-width: 1000px) {
    width: 90%;
  }
`;

const InnerImageAnimLayer = styled(motion.div)`
  width: 100%;
  max-height: 575px;
  height: 100%;
`;

const StyledLazyImage = styled(LazyImage)`
  width: 100%;
  max-height: 575px;
  height: 100%;
`;

const PostCategory = styled(motion.div)`
  display: flex;
  color: #fff;
  font-size: 15px;
  letter-spacing: 1px;
  font-weight: 500;
  margin-bottom: 5px;
`;

const StyledParallax = styled(Parallax)`
  width: 100%;
  height: 100%;
`;

const PostCategoryItem = styled.div`
  margin-right: 5px;
`;

const HeaderContainerAnimLayer = styled(motion.div)`
  height: 100%;
  overflow: hidden;
  width: 100%;
  margin-top: -100px;
  padding-bottom: 100px;
  padding-top: 100px;
`;

const NoImageSpacer = styled.div`
  margin-bottom: 0px;
`;

const HeaderAnim = {
  hidden: { height: 0 },
  visible: {
    height: "100%",
    transition: {
      duration: 0.4,
      delay: 0.4,
    },
  },
};

const BackgroundAnim = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.4,
      delay: 0.8,
    },
  },
};

const CategoryAnim = {
  hidden: { opacity: 0, y: -20 },
  visible: {
    opacity: 1,
    y: 20,
    transition: {
      delay: 0.6,
    },
  },
};

const TitleAnim = {
  hidden: { opacity: 0, y: -20 },
  visible: {
    opacity: 1,
    y: 20,
    transition: {
      delay: 0.65,
    },
  },
};

const AuthorAnim = {
  hidden: { opacity: 0, y: -20 },
  visible: {
    opacity: 1,
    y: 20,
    transition: {
      delay: 0.7,
    },
  },
};

const DateAnim = {
  hidden: { opacity: 0, y: 0 },
  visible: {
    opacity: 1,
    y: 20,
    transition: {
      delay: 0.8,
    },
  },
};

const ImageAnim = {
  hidden: { opacity: 0, y: -20 },
  visible: {
    opacity: 1,
    y: 20,
    transition: {
      delay: 0.8,
    },
  },
};

const InnerImageAnim = {
  hidden: { scale: 1.1 },
  visible: {
    scale: 1,

    transition: {
      delay: 0.6,
      duration: 10,
    },
  },
};

function ArticleHeaderVariant({
  featuredImage,
  title,
  author,
  dateWritten,
  postCategories,
}) {
  const [isInView, setIsInView] = useState(true);

  const { headerRef, inView } = useInView({
    threshold: 0.1,
    onEnter: ({ scrollDirection, entry, observe, unobserve }) => {
      setIsInView(true);
    },
  });

  return (
    <HeaderContainerOuter ref={headerRef}>
      <HeaderContainer>
        <HeaderContent>
          <PostCategory
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            variants={CategoryAnim}
          >
            {postCategories &&
              postCategories.map((category, i) => (
                <PostCategoryItem>
                  {category.content && category.content.prettyTitle}
                  {i !== postCategories.length - 1 && ", "}
                </PostCategoryItem>
              ))}
          </PostCategory>
          <Title
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            variants={TitleAnim}
          >
            {title}
          </Title>
          <Metadata>
            <Author
              initial="hidden"
              animate={isInView ? "visible" : "hidden"}
              variants={AuthorAnim}
            >
              Words By {author ? author : "Leeds United Supporters' Trust"}
            </Author>
            <DateWritten
              initial="hidden"
              animate={isInView ? "visible" : "hidden"}
              variants={DateAnim}
            >
              <Moment format="Do MMMM, YYYY" date={dateWritten} />
            </DateWritten>
          </Metadata>
        </HeaderContent>
        <BackgroundAnimLayer
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          variants={HeaderAnim}
        >
          <BackgroundLayer
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            variants={BackgroundAnim}
            image={HeaderImage}
          />
        </BackgroundAnimLayer>
      </HeaderContainer>

      {featuredImage && featuredImage.src ? (
        <HeaderImageContainer
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          variants={ImageAnim}
        >
          <InnerImageAnimLayer
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            variants={InnerImageAnim}
          >
            <StyledLazyImage fluid={featuredImage && featuredImage} />
          </InnerImageAnimLayer>
        </HeaderImageContainer>
      ) : (
        <NoImageSpacer />
      )}
    </HeaderContainerOuter>
  );
}

export default ArticleHeaderVariant;
