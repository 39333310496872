import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

import CtaButton from "./ctaButton";

const PricingBlockCardContainer = styled.div`
  background: #2b3445;
  border-radius: 10px;
  max-width: 400px;
  overflow: hidden;
  width: 100%;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  padding-bottom: 39px;
  margin: 0px 25px;
  margin-bottom: 25px;
`;

const Title = styled.div`
  font-size: 24px;
  line-height: 111%;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.3em;
  color: #ffffff;
  margin-top: 16px;
`;

const TitleDivider = styled.div`
  width: 78px;
  height: 1px;
  background: #fbf200;
  margin-top: 8px;
  margin-bottom: 16px;
`;

const Pricing = styled.div`
  font-size: 92px;
  line-height: 98px;
  font-weight: 100;
  letter-spacing: 10px;
  span {
    font-size: 22px;
    font-weight: 300;
    color: #f2e858;
  }
`;

const TimePeriod = styled.div`
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  line-height: 32px;
  margin-top: -30px;
  margin-bottom: 20px;
  font-size: 11px;
  letter-spacing: 2px;
  color: #f2e858;
`;

const BenefitsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

const Benefit = styled.div`
  font-size: 16px;
  line-height: 24px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const BenefitText = styled.div`
  font-family: Metropolis;
  font-size: 16px;
  line-height: 167%;
  max-width: 195px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  margin: 8px 0px;
  letter-spacing: 1.3px;
  @media (max-width: 450px) {
    max-width: 80%;
  }
`;

const CtaButtonContainer = styled.div`
  width: 80%;
  margin-top: 20px;
`;

function PricingBlockCard({
  benefits,
  price,
  title,
  ctaText,
  ctaLink,
  ctaMode,
  ctaBgColor,
  ctaBgColorHover,
  ctaColor,
  ctaPadding,
  pricingFrequency,
}) {
  return (
    <PricingBlockCardContainer>
      <Title>{title}</Title>
      <TitleDivider />
      <Pricing>
        <span>£</span>
        {price}
      </Pricing>
      <TimePeriod>{pricingFrequency}</TimePeriod>
      <BenefitsContainer>
        {benefits.map((benefit, i) => (
          <Benefit key={i}>
            <BenefitText>{benefit}</BenefitText>
          </Benefit>
        ))}
      </BenefitsContainer>
      <CtaButtonContainer>
        <CtaButton
          text={ctaText}
          link={ctaLink}
          backgroundColor={ctaBgColor}
          backgroundColorHover={ctaBgColorHover}
          color={ctaColor}
          padding={ctaPadding}
          mode={ctaMode}
        />
      </CtaButtonContainer>
    </PricingBlockCardContainer>
  );
}

export default PricingBlockCard;
