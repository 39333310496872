import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import useInView from "react-cool-inview";

import BlogFiltering from "./blogFiltering";
import BlogSearch from "./blogSearch";

const BlogControlsContainerOuter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 44px 0px;
  @media (max-width: 768px) {
    padding-top: 0;
    padding-bottom: 30px;
  }
`;

const BlogControlsContainerInner = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

function BlogControls({ setSearchInput, setActiveCategory }) {
  return (
    <BlogControlsContainerOuter>
      <BlogControlsContainerInner>
        <BlogFiltering setActiveCategory={setActiveCategory} />
        <BlogSearch setMasterSearchInput={setSearchInput} />
      </BlogControlsContainerInner>
    </BlogControlsContainerOuter>
  );
}

export default BlogControls;
