import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import useInView from "react-cool-inview";
import { Parallax } from "react-scroll-parallax";
import { Link } from "gatsby";
import EllipsisText from "react-ellipsis-text";
import Moment from "react-moment";

import LazyImage from "./lazy-image";

import Bamford from "../assets/images/bamford.jpg";
import ReadMoreArrow from "../assets/images/icons/readMoreArrow.svg";

const RootPageArticleLinkContainer = styled(motion.div)`
  width: 100%;
  position: relative;
  margin-bottom: 250px;
  cursor: pointer;
  @media (min-width: 1310px) {
    max-width: 420px;
  }
  @media (min-width: 896px) {
    max-width: 540px;
  }
  @media (max-width: 768px) {
    margin-bottom: 170px;
  }
`;

const ImageContainer = styled(Link)`
  width: 100%;
  height: 274px;
  min-width: 385px;
  min-height: 274px;

  max-height: 274px;
  border-radius: 0px 10px 0px 0px;
  background-color: #2c3445;
  overflow: hidden;
  @media (max-width: 768px) {
    min-width: unset;
  }
`;

const ImageInnerContainer = styled(motion.div)`
  height: 274px;
  min-width: 385px;
  min-height: 274px;
  max-height: 274px;
  background-color: #f3ebeb;
  @media (max-width: 768px) {
    min-width: unset;
    min-height: unset;
    height: 234px;
  }
`;

const StyledLazyImage = styled(LazyImage)`
  height: 274px;
  min-width: 385px;
  min-height: 274px;
  max-height: 274px;
  @media (max-width: 768px) {
    min-width: unset;
    min-height: unset;
    height: 234px;
  }
`;

const ContentContainer = styled(motion.div)`
  position: absolute;
  width: 80%;
  min-height: 223px;
  height: fit-content;
  background: #ffffff;
  border-radius: 0px 10px 10px 5px;
  bottom: -160px;
  padding: 33px 27px;
  @media (max-width: 1310px) {
    min-width: 293px;
  }
  @media (max-width: 768px) {
    min-width: unset;
    width: 95%;
  }
`;

const ContentContainerInner = styled(Link)`
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: unset;
`;

const TextContainer = styled(motion.div)``;

const Title = styled.h2`
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #000000;
`;

const TitleInner = styled(EllipsisText)``;

const MetaContainer = styled.div`
  margin-top: 30px;
  position: absolute;
  left: 0;
  bottom: 0;
  margin-left: 27px;
  margin-bottom: 25px;
`;

const MetaDivider = styled.div`
  width: 31px;
  height: 1px;
  background-color: #2c3445;
  margin-bottom: 12px;
`;

const PubDate = styled.div`
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  color: #000000;
  opacity: 0.8;
  font-weight: 600;
`;

const AdditionalTextContent = styled.div``;

const Description = styled(motion.div)`
  font-size: 14px;
  line-height: 21px;
  color: #000000;
`;

const DescriptionInner = styled(EllipsisText)``;

const SeeMoreButton = styled(motion.div)`
  background-color: #6d7992;
  padding: 8px 12px 6px 12px;
  width: fit-content;
  margin-top: 20px;
  cursor: pointer;
  display: flex;
`;

const SeeMoreText = styled.div`
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  margin-right: 10px;
`;

const SeeMoreArrow = styled.div`
  margin-top: -2px;
`;

const ImageHoverAnim = {
  hidden: {
    opacity: 1,
  },
  notVisible: {
    opacity: 0.5,
    transition: {
      duration: 0.6,
    },
  },
};

const ContentCardHoverAnim = {
  hidden: {
    height: "223px",
  },
  notVisible: {
    height: "410px",
    transition: {
      duration: 0.3,
    },
  },
};

const DescAnim = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.2,
    },
  },
};

const SeeMoreAnim = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.3,
    },
  },
};

function RootPageArticleLink({ link, thumbnail, postDate, title, snippet }) {
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsHovered(false);
    }, 100);
  }, []);

  return (
    <RootPageArticleLinkContainer
      onMouseEnter={() => setIsHovered(false)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <ImageContainer to={link}>
        <ImageInnerContainer
          initial="hidden"
          animate={isHovered ? "visible" : "hidden"}
          variants={ImageHoverAnim}
          to={link}
        >
          <StyledLazyImage fluid={thumbnail} />
        </ImageInnerContainer>
      </ImageContainer>
      <ContentContainer
        initial="hidden"
        animate={isHovered ? "hidden" : "hidden"}
        variants={ContentCardHoverAnim}
      >
        <ContentContainerInner to={link}>
          <TextContainer>
            <Title>
              <TitleInner length={"90"} text={title} />
            </Title>
            {isHovered && (
              <AdditionalTextContent>
                <Description
                  initial="hidden"
                  animate={isHovered ? "visible" : "hidden"}
                  variants={DescAnim}
                >
                  <DescriptionInner length={"140"} text={snippet} />
                </Description>
                <SeeMoreButton
                  initial="hidden"
                  animate={isHovered ? "visible" : "hidden"}
                  variants={SeeMoreAnim}
                >
                  <SeeMoreText>Read More</SeeMoreText>
                  <SeeMoreArrow>
                    <ReadMoreArrow />
                  </SeeMoreArrow>
                </SeeMoreButton>
              </AdditionalTextContent>
            )}
          </TextContainer>
          <MetaContainer>
            <MetaDivider />
            <PubDate>
              <Moment format="Do MMMM, YYYY" date={postDate} />
            </PubDate>
          </MetaContainer>
        </ContentContainerInner>
      </ContentContainer>
    </RootPageArticleLinkContainer>
  );
}

export default RootPageArticleLink;
