import React from "react";

import LargeCtaBanner from "../../largeCtaBanner";
import linkParse from "../../../utils/link-parser";

function StoryblokLargeCtaBannerAdapter({ ctaLink, ...rest }) {
  return <LargeCtaBanner link={linkParse(ctaLink)} {...rest} />;
}

export default StoryblokLargeCtaBannerAdapter;
