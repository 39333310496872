import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import useInView from "react-cool-inview";

import { getFluidGatsbyImage } from "../utils/gatsby-storyblok-image-fixed/";
import ArticleLinkBlock from "./articleLinkBlock";

const NewsRow = styled(motion.div)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  width: 100%;
  z-index: 5;
  background-color: #f8f8f8;
  @media (max-width: 768px) {
    display: none;
  }
`;

const PostOuterContainer = styled(motion.div)`
  width: 348px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  z-index: 5;
`;

const NewsAnim = {
  hidden: {
    opacity: 1,
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.15,
      delayChildren: 0.1,
    },
  },
};

const NewsItemAnim = {
  hidden: { opacity: 0, y: 40 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.4,
    },
  },
};

function HomepageNewsRow({ data }) {
  const [isInView, setIsInView] = useState(false);

  const { ref, inView } = useInView({
    threshold: 0.1,
    onEnter: ({ scrollDirection, entry, observe, unobserve }) => {
      setIsInView(true);
    },
  });
  return (
    <NewsRow
      ref={ref}
      initial="hidden"
      animate={isInView ? "visible" : "hidden"}
      variants={NewsAnim}
    >
      {data.slice(3, 6).map((article, i) => {
        let articleData = JSON.parse(article.node.content);
        let articleImage = getFluidGatsbyImage(articleData.thumbnailImage, {
          maxWidth: 800,
        });

        return (
          <PostOuterContainer key={i} variants={NewsItemAnim}>
            <ArticleLinkBlock
              link={article.node.full_slug}
              thumbnail={articleImage}
              title={article.node.name}
              snippet={articleData.postSnippet}
            />
          </PostOuterContainer>
        );
      })}
    </NewsRow>
  );
}

export default HomepageNewsRow;
