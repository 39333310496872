import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Parallax } from "react-scroll-parallax";

import LazyImage from "./lazy-image";

const AnimatedHeaderContainerOuter = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: -100px;
  overflow: hidden;
  max-height: 850px;
  position: relative;
`;

const AnimatedHeaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const LeftContent = styled(motion.div)`
  width: 50%;
  z-index: 3;
`;

const LeftBlockInner = styled(motion.div)`
  background-color: #2c3445;
  height: 100%;
  width: 100%;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RightContent = styled(motion.div)`
  width: 50%;
  z-index: 1;
`;

const ImageContainer = styled(motion.div)`
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;
  background-color: #e4d9d5;
`;

const ImageItem = styled(LazyImage)`
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 550px;
  z-index: 3;
  @media (max-width: 1160px) {
    width: 90%;
  }
`;

const Title = styled(motion.div)`
  display: flex;
  font-size: 70px;
  align-items: center;
  font-weight: 600;
  line-height: 120px;
  color: #fff;
  z-index: 3;
  @media (max-width: 1100px) {
    font-size: 58px;
    line-height: 85px;
    font-weight: 700;
  }
  @media (max-width: 440px) {
    font-size: 32px;
    line-height: 60px;
    font-weight: 700;
  }
`;

const Subtitle = styled(motion.div)`
  color: #fff;
  z-index: 3;
  @media (max-width: 1100px) {
    font-size: 16px;
  }
`;

const InitialAnimationblock = styled(motion.div)`
  height: 100%;
  z-index: 2;
  width: 50%;
  position: absolute;
`;

const InitialAnimationblockInner = styled(motion.div)`
  background-color: #fde115;
  height: 100%;
  z-index: 2;
  width: 100%;
`;

const StyledParallax = styled(Parallax)`
  width: 100%;
  height: 100%;
`;

const FirstBlockAnim = {
  hidden: {
    x: "200vh",
  },
  visible: {
    x: 0,

    transition: {
      delay: 0,
      duration: 0.7,
      ease: "easeIn",
    },
  },
};

const FirstBlockInnerAnim = {
  hidden: {
    width: "200%",
  },
  visible: {
    width: "100%",
    transition: {
      delay: 0.4,
      duration: 0.3,
      ease: "easeIn",
    },
  },
};

const LeftBlockAnim = {
  hidden: {
    x: "200vh",
  },
  visible: {
    x: 0,

    transition: {
      delay: 0.35,
      duration: 0.4,
      ease: "easeIn",
    },
  },
};

const LeftBlockInnerAnim = {
  hidden: {
    width: "200%",
  },
  visible: {
    width: "100%",
    transition: {
      delay: 0.6,
      duration: 0.4,
      ease: "easeIn",
    },
  },
};

const RightBlockAnim = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.8,
      duration: 0.1,
      ease: "easeIn",
    },
  },
};

const ImageContainerAnim = {
  hidden: {
    scale: 1.2,
  },
  visible: {
    scale: 1.15,

    transition: {
      delay: 0.8,
      duration: 7,
      ease: "easeOut",
    },
  },
};

const TitleAnim = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 1.4,
      duration: 0.3,
      ease: "easeOut",
    },
  },
};

const SubTitleAnim = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 1.45,
      duration: 0.3,
      ease: "easeOut",
    },
  },
};

function AnimatedHeader({ image }) {
  const [isInView, setIsInView] = useState(true);
  return (
    <AnimatedHeaderContainerOuter>
      <AnimatedHeaderContainer>
        <InitialAnimationblock
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          variants={FirstBlockAnim}
        >
          <InitialAnimationblockInner
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            variants={FirstBlockInnerAnim}
          />
        </InitialAnimationblock>
        <LeftContent
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          variants={LeftBlockAnim}
        >
          <LeftBlockInner
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            variants={LeftBlockInnerAnim}
          >
            <TextContent>
              <Title
                initial="hidden"
                animate={isInView ? "visible" : "hidden"}
                variants={TitleAnim}
              >
                About Us
              </Title>
              <Subtitle
                initial="hidden"
                animate={isInView ? "visible" : "hidden"}
                variants={SubTitleAnim}
              >
                <p>We believe that as fans, we're stronger together. </p>

                <p>
                  We seek to work with Football Supporters' Federation,
                  Supporters Direct, LUFC (and its supporters), to promote sound
                  governance, a healthy supporter experience, the development of
                  a sustainable club with active supporter involvement, and
                  community values, at its core.
                </p>
              </Subtitle>
            </TextContent>
          </LeftBlockInner>
        </LeftContent>
        <RightContent
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          variants={RightBlockAnim}
        >
          <StyledParallax
            y={[-20, 20]}
            styleInner={{ width: "100%", height: "100%" }}
          >
            <ImageContainer
              initial="hidden"
              animate={isInView ? "visible" : "hidden"}
              variants={ImageContainerAnim}
            >
              <ImageItem fluid={image} />
            </ImageContainer>
          </StyledParallax>
        </RightContent>
      </AnimatedHeaderContainer>
    </AnimatedHeaderContainerOuter>
  );
}

export default AnimatedHeader;
