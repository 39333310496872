import React, { useState } from "react";
import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import { render } from "storyblok-rich-text-react-renderer";

import useInView from "react-cool-inview";

import LazyImage from "./lazy-image";

const TitleAndTextContainer = styled.div`
  padding-top: 45px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  @media (max-width: 768px) {
    padding-top: 0px;
  }
`;

const TitleAndTextContent = styled.div`
  max-width: calc(100% - 40px);
  width: 100%;
  margin: auto;
  padding: 60px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.backgroundColor};
  @media (max-width: 950px) {
    padding: 0px;
  }
`;

const TitleAndTextContainerInner = styled.div`
  max-width: 1100px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  @media (max-width: 950px) {
    flex-direction: column;
  }
  @media (max-width: 650px) {
    padding: 0px;
  }
`;

const TextContent = styled.div``;

const Subtitle = styled.div`
  font-size: 14px;
  color: ${(props) => props.color};
  margin-bottom: 22px;
  letter-spacing: 3.5px;
  text-transform: uppercase;
  z-index: 2;
`;

const Title = styled(motion.h4)`
  font-weight: 700;
  font-size: 62px;
  line-height: 62px;
  letter-spacing: -1px;
  color: ${(props) => props.color};
  font-family: "Poppins";
  margin-bottom: 15px;
  z-index: 2;
  @media (max-width: 1080px) {
    font-size: 52px;
  }
  @media (max-width: 768px) {
    font-size: 44px;
    line-height: 46px;
  }
`;

const Paragraph = styled(motion.div)`
  font-weight: 500;
  font-size: 17px;
  line-height: 28px;
  color: ${(props) => props.color};
  margin-top: 30px;
  margin-bottom: 30px;
  max-width: 70%;
  z-index: 2;
  @media (max-width: 1490px) {
    max-width: 600px;
  }

  @media (max-width: 1230px) {
    max-width: 500px;
  }
  @media (max-width: 1080px) {
    max-width: 400px;
  }
  @media (max-width: 950px) {
    max-width: 75%;
  }
  @media (max-width: 768px) {
    max-width: 100%;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  ${(props) =>
    props.fullWidth &&
    css`
      max-width: 100% !important;
    `}
`;

const ParagraphContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const RightImageContainer = styled(motion.div)`
  position: absolute !important;
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  margin-right: 20px;
  @media (max-width: 1080px) {
    margin-right: 0px;
  }
  @media (max-width: 950px) {
    position: relative !important;
    width: 100%;
    margin-bottom: 25px;
  }
`;

const RightImage = styled(LazyImage)`
  width: 100%;
  max-height: 600px;
  object-fit: contain !important;
`;

const TitleAnim = {
  hidden: {
    opacity: 0,
    y: 30,
    transition: {
      delay: 0,
      duration: 0.2,
    },
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.3,
    },
  },
};

const ParagraphAnim = {
  hidden: {
    opacity: 0,
    y: 30,
    transition: {
      delay: 0,
      duration: 0.2,
    },
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.4,
    },
  },
};

const ImageAnim = {
  hidden: {
    opacity: 0,
    x: 30,
    transition: {
      delay: 0,
      duration: 0.2,
    },
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      delay: 0.5,
    },
  },
};

function TitleAndText({
  title,
  subtitle,
  paragraph,
  backgroundMode,
  backgroundColor,
  titleColor,
  textColor,
  subtitleColor,
  paragraphTwo,
  hideImageOnMobile,
  image,
  windowWidth,
  fullWidth,
}) {
  const [isInView, setIsInView] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0.25,
    onEnter: ({ scrollDirection, entry, observe, unobserve }) => {
      setIsInView(true);
    },
  });
  return (
    <TitleAndTextContainer ref={ref}>
      <TitleAndTextContent
        backgroundColor={backgroundMode ? backgroundColor : "#fff"}
      >
        <TitleAndTextContainerInner>
          <TextContent>
            <Subtitle color={subtitleColor ? subtitleColor : "#000"}>
              {subtitle}
            </Subtitle>
            <Title
              animate={isInView ? "visible" : "hidden"}
              variants={TitleAnim}
              initial="hidden"
              color={titleColor ? titleColor : "#000"}
            >
              {title}
            </Title>
            <ParagraphContainer>
              <Paragraph
                color={textColor ? textColor : "#000"}
                animate={isInView ? "visible" : "hidden"}
                initial="hidden"
                variants={ParagraphAnim}
                dangerouslySetInnerHTML={paragraph}
                fullWidth={fullWidth}
              />
            </ParagraphContainer>
          </TextContent>
          {!fullWidth && (
            <>
              {hideImageOnMobile ? (
                windowWidth < 768 ? null : (
                  <RightImageContainer
                    animate={isInView ? "visible" : "hidden"}
                    variants={ImageAnim}
                    initial="hidden"
                  >
                    <RightImage
                      fluid={image}
                      objectFit={"contain"}
                      objectPosition={"right center"}
                    />
                  </RightImageContainer>
                )
              ) : (
                <RightImageContainer
                  animate={isInView ? "visible" : "hidden"}
                  variants={ImageAnim}
                  initial="hidden"
                >
                  <RightImage
                    fluid={image}
                    objectFit={"contain"}
                    objectPosition={"right center"}
                  />
                </RightImageContainer>
              )}
            </>
          )}
        </TitleAndTextContainerInner>
      </TitleAndTextContent>
    </TitleAndTextContainer>
  );
}

export default TitleAndText;
