import React from "react";
import QuoteBlock from "../../quoteBlock";

function StoryblokQuoteBlock({ backgroundColor, textColor, ...rest }) {
  return (
    <QuoteBlock
      backgroundColor={backgroundColor.color}
      textColor={textColor.color}
      {...rest}
    />
  );
}

export default StoryblokQuoteBlock;
