import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { motion } from "framer-motion";

const TimelineControlItemContainer = styled(motion.div)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 25px;
  z-index: 2;
`;

const TimelineControl = styled(motion.div)`
  cursor: pointer;
  transition-duration: 400ms;
  font-size: 20px;
  letter-spacing: 2px;
  font-weight: 300;
  ${(props) =>
    props.active &&
    css`
      transition-duration: 400ms;
      color: #fde115;
    `}
`;

const TimelineMarker = styled(motion.div)`
  height: 2px;
  background-color: #fff;
  width: 30px;
  margin-right: 10px;
  transition-duration: 400ms;
  ${(props) =>
    props.active &&
    css`
      transition-duration: 400ms;
      background-color: #fde115;
      width: 60px;
    `}
`;

const Desktop = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 900px) {
    display: none;
  }
`;

const Mobile = styled.div`
  display: none;
  @media (max-width: 900px) {
    display: block;
  }
`;

const MobileYear = styled.div`
  padding-left: 15px;
  font-size: 14px;
  cursor: pointer;
  ${(props) =>
    props.active &&
    css`
      color: #fde115;
    `}
`;

const LineAnim = {
  hidden: {
    width: "30px",
    marginRight: "10px",
    transition: {
      delay: 0.0,
      duration: 0.02,
    },
  },
  visible: {
    width: "35px",
    marginRight: "15px",
    transition: {
      delay: 0.0,
      duration: 0.02,
    },
  },
};

const ActiveLineAnim = {
  hidden: {
    width: "45px",
    marginRight: "10px",
    transition: {
      delay: 0.0,
      duration: 0.02,
    },
  },
  visible: {
    width: "50px",
    marginRight: "20px",
    transition: {
      delay: 0.0,
      duration: 0.02,
    },
  },
};

const YearAnim = {
  hidden: {
    marginLeft: 0,
  },
  visible: {
    marginLeft: "0px",
  },
};

function TimelineControlItem({ i, setActiveItem, active, item }) {
  const [isHovered, setIsHovered] = useState(false);
  useEffect(() => {
    setIsHovered(false);
  }, [active]);
  return (
    <TimelineControlItemContainer
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Desktop>
        {active ? (
          <TimelineMarker
            active={active}
            initial="hidden"
            animate={isHovered ? "visible" : "hidden"}
            variants={ActiveLineAnim}
          />
        ) : (
          <TimelineMarker
            active={active}
            initial="hidden"
            animate={isHovered ? "visible" : "hidden"}
            variants={LineAnim}
          />
        )}

        <TimelineControl
          initial="hidden"
          animate={isHovered ? "visible" : "hidden"}
          variants={YearAnim}
          active={active}
          onClick={() => setActiveItem(i)}
        >
          {item.year}
        </TimelineControl>
      </Desktop>
      <Mobile>
        <MobileYear active={active} onClick={() => setActiveItem(i)}>
          {item.year}
        </MobileYear>
      </Mobile>
    </TimelineControlItemContainer>
  );
}

export default TimelineControlItem;
