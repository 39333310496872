import React from "react";

import { getFluidGatsbyImage } from "../../utils/gatsby-storyblok-image-fixed";
import Article from "../article";

function StoryblokBlogArticleComponent({
  pageName,
  author,
  featuredImage,
  ...rest
}) {
  let fluidFeaturedImage = null;
  if (featuredImage && featuredImage.filename !== null) {
    fluidFeaturedImage = getFluidGatsbyImage(featuredImage, {
      maxWidth: 1920,
    });
  }
  return (
    <Article
      {...rest}
      title={pageName}
      author={author}
      featuredImage={fluidFeaturedImage}
    />
  );
}

export default StoryblokBlogArticleComponent;
