import React, { useState } from "react";
import styled, { css } from "styled-components";
import { motion } from "framer-motion";

import TimelineItem from "./timelineItem";
import TimelineControlItem from "./timelineControlItem";
import TimelineControlItemActive from "./timlineControlItemActive";

const TimelineContainerOuter = styled.div`
  min-height: 100vh;
  padding: 150px 0px 50px 0px;
  background-color: #2d3445;
  color: #fff;
  position: relative;
  @media (max-width: 900px) {
    padding: 50px 20px;
  }
`;

const TitleContainerOuter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TitleContainer = styled.div`
  margin-bottom: 50px;
  width: 100%;
  max-width: 1100px;
`;

const Subtitle = styled.div`
  font-weight: 500;
  font-size: 17px;
  line-height: 28px;
  color: ${(props) => props.color};
  margin-top: 30px;
  margin-bottom: 30px;
  max-width: 70%;
  z-index: 2;
  @media (max-width: 1490px) {
    max-width: 600px;
  }
  @media (max-width: 1230px) {
    max-width: 500px;
  }
  @media (max-width: 1080px) {
    max-width: 400px;
  }
  @media (max-width: 950px) {
    max-width: 75%;
  }
  @media (max-width: 768px) {
    max-width: 100%;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

const Title = styled(motion.h4)`
  font-weight: 700;
  font-size: 62px;
  line-height: 62px;
  letter-spacing: -1px;
  color: ${(props) => props.color};
  font-family: "Poppins";
  margin-bottom: 15px;
  z-index: 2;
  @media (max-width: 1080px) {
    font-size: 52px;
  }
  @media (max-width: 768px) {
    font-size: 44px;
    line-height: 46px;
  }
`;

const TimelineContainer = styled.div`
  height: 100%;
`;

const TimelineControlsContainer = styled.div`
  z-index: 5;
`;

const TimelineControlOuter = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 5;
`;

const TimelineItemContainerOuter = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TimelineItemContainer = styled.div`
  margin-bottom: 50px;
  width: 100%;
  max-width: 1100px;
  @media (max-width: 900px) {
    margin-bottom: 15px;
  }
`;

function Timeline({ items, title, subtitle }) {
  const [activeItem, setActiveItem] = useState(0);

  return (
    <TimelineContainerOuter>
      <TitleContainerOuter>
        <TitleContainer>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </TitleContainer>
      </TitleContainerOuter>
      <TimelineContainer>
        <TimelineControlOuter>
          <TimelineControlsContainer>
            {items.map((item, i) => (
              <>
                {i === activeItem ? (
                  <TimelineControlItemActive
                    key={i}
                    i={i}
                    setActiveItem={setActiveItem}
                    active={true}
                    item={item}
                  />
                ) : (
                  <TimelineControlItem
                    key={i}
                    i={i}
                    setActiveItem={setActiveItem}
                    active={false}
                    item={item}
                  />
                )}
              </>
            ))}
          </TimelineControlsContainer>
        </TimelineControlOuter>
        <TimelineItemContainerOuter>
          <TimelineItemContainer>
            <TimelineItem content={items[activeItem]} />
          </TimelineItemContainer>
        </TimelineItemContainerOuter>
      </TimelineContainer>
    </TimelineContainerOuter>
  );
}

export default Timeline;
