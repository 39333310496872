import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Link } from "gatsby";
import useInView from "react-cool-inview";
import { motion } from "framer-motion";

import LazyImage from "./lazy-image";
import RichtextParser from "../utils/richtext-parser";
import LinkParser from "../utils/link-parser";
import convertHtml from "../utils/convert-html";
import WhatWeDoCaseStudyItem from "./whatWeDoCaseStudyItem";

const WhatWeDoItemContainer = styled.div`
  margin-bottom: 150px;
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.reverse &&
    css`
      align-items: flex-end;
    `};
  @media (max-width: 768px) {
    margin-bottom: 75px;
  }
  &:last-of-type {
    @media (max-width: 768px) {
      margin-bottom: 0px;
    }
  }
`;

const Title = styled(motion.div)`
  font-weight: 700;
  font-size: 42px;
  line-height: 45px;
  letter-spacing: -1px;
  display: flex;
  align-items: center;
  color: #fbf200;
  ${(props) =>
    props.reverse &&
    css`
      justify-content: flex-end;
    `};
  @media (max-width: 1100px) {
    font-size: 40px;
    line-height: 50px;
  }
  @media (max-width: 768px) {
    font-size: 35px;
    line-height: 38px;
  }
`;

const Paragraph = styled(motion.div)`
  max-width: 760px;
  margin-top: 10px;
  font-weight: 500;
  font-size: 17px;
  line-height: 28px;
  color: #fff;

  z-index: 2;

  ${(props) =>
    props.reverse &&
    css`
      text-align: right;
    `};
`;

const TitleAnim = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0,
      duration: 0.3,
    },
  },
};

const ParagraphAnim = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.1,
      duration: 0.4,
      ease: "easeIn",
    },
  },
};

function WhatWeDoItem({
  title,
  paragraph,
  caseStudyImage,
  caseStudySubtext,
  caseStudyTitle,
  ctaButtonText,
  ctaButtonLink,
  enableCta,
  reverse,
}) {
  const [isInView, setIsInView] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0.25,
    onEnter: ({ scrollDirection, entry, observe, unobserve }) => {
      setIsInView(true);
    },
  });
  return (
    <WhatWeDoItemContainer reverse={reverse} ref={ref}>
      <Title
        reverse={reverse}
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        variants={TitleAnim}
      >
        {title}
      </Title>
      <Paragraph
        reverse={reverse}
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        variants={ParagraphAnim}
        dangerouslySetInnerHTML={convertHtml(RichtextParser(paragraph))}
      />

      <WhatWeDoCaseStudyItem
        caseStudyImage={caseStudyImage}
        caseStudyTitle={caseStudyTitle}
        caseStudySubtext={caseStudySubtext}
        enableCta={enableCta}
        ctaButtonLink={ctaButtonLink}
        ctaButtonText={ctaButtonText}
        reverse={reverse}
      />
    </WhatWeDoItemContainer>
  );
}

export default WhatWeDoItem;
