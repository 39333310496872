import React, { useState } from "react";
import styled, { css } from "styled-components";
import { motion, AnimateSharedLayout, AnimatePresence } from "framer-motion";

import TabContent from "./tabContent";

const TabbedContainer = styled(motion.div)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TabbedContentContainer = styled(motion.div)`
  max-width: calc(100%);
  width: 100%;
  margin: auto;
  padding: 80px 0px 60px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.backgroundColor};
  @media (max-width: 768px) {
    padding: 40px 0px 20px 0px;
  }
`;

const TabbedContainerInner = styled(motion.div)`
  max-width: 1100px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`;

const TabBarContainer = styled(motion.div)`
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
  overflow-x: auto;
  overflow-y: hidden;
`;

const TabTitleContainer = styled(motion.div)`
  display: flex;
  justify-content: flex-start;
  height: 45px;
  flex-direction: column;
  margin-right: 30px;
`;

const TabTitle = styled(motion.div)`
  font-weight: 500;
  font-size: 22px;
  line-height: 22px;
  white-space: nowrap;
  color: ${(props) => props.color};
  font-family: "Poppins";

  cursor: pointer;
  ${(props) =>
    props.active &&
    css`
      color: ${(props) => props.highlightColor};
    `}
  transition: 400ms;
  :hover {
    color: ${(props) => props.highlightColor};
    transition: 400ms;
  }
`;

const TabHighlight = styled(motion.div)`
  width: 100%;
  height: 4px;
  margin-top: 5px;
  background-color: ${(props) => props.color};
`;

const TabContentContainer = styled(motion.div)`
  height: fit-content;
`;

function TabbedContent({
  backgroundColor,
  titleColor,
  textColor,
  highlightColor,
  tabs,
}) {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <TabbedContainer>
      <TabbedContentContainer
        backgroundColor={backgroundColor ? backgroundColor : "#fff"}
      >
        <TabbedContainerInner>
          <AnimateSharedLayout>
            <TabBarContainer>
              {tabs.map((tab, i) => (
                <TabTitleContainer key={i}>
                  <TabTitle
                    key={tab.key}
                    active={activeTab === i ? true : false}
                    color={titleColor ? titleColor : "#000"}
                    highlightColor={highlightColor ? highlightColor : "#153BF4"}
                    onClick={() => setActiveTab(i)}
                  >
                    {tab.title}
                  </TabTitle>
                  {activeTab === i ? (
                    <TabHighlight
                      color={highlightColor ? highlightColor : "#153BF4"}
                      layoutId="highlight"
                    />
                  ) : null}
                </TabTitleContainer>
              ))}
            </TabBarContainer>
          </AnimateSharedLayout>
          <AnimatePresence>
            <motion.div layout="position">
              <TabContent
                data={tabs[activeTab]}
                color={textColor}
                highlight={highlightColor}
                titleColor={titleColor}
              />
            </motion.div>
          </AnimatePresence>
        </TabbedContainerInner>
      </TabbedContentContainer>
    </TabbedContainer>
  );
}

export default TabbedContent;
