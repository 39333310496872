import React from "react";

import Objectives from "../../objectives";

function StoryblokObjectiveAdapter({ ...rest }) {
  if (typeof window === `undefined`) {
    return <></>;
  }
  return <Objectives {...rest} />;
}

export default StoryblokObjectiveAdapter;
