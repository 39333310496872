import { useMemo } from "react";

import StoryblokPage from "../../components/storyblok/storyblok-page";
import StoryblokArticleComponent from "./storyblok-article-component";
import StoryblokArticleRootPage from "./storyblok-article-root-page";
import StoryblokTextAdapter from "./adapters/storyblok-text-adapter";
import StoryblokArticleInlineImageAdapter from "./adapters/storyblok-inline-image-adapter";
import StoryblokHomeHeaderAdapter from "./adapters/storyblok-home-header-adapter";
import StoryblokHomeWhatWeDoAdapter from "./adapters/storyblok-home-what-we-do-adapter";
import StoryblokAnimatedHeaderAdapter from "./adapters/storyblok-animated-header-adapter";
import StoryblokTimelineAdapter from "./adapters/storyblok-timeline-adapter";
import StoryblokBoardMembersAdapter from "./adapters/storyblok-board-members-adapter";
import StoryblokMotmHomeBannerAdapter from "./adapters/storyblok-motm-home-banner-adapter";
import StoryblokLargeCtaBannerAdapter from "./adapters/storyblok-large-cta-banner-adapter";
import StoryblokAnimatedGlobe from "./adapters/storyblok-animated-globe";
import StoryblokTextHeaderAdapter from "./adapters/storyblok-text-header-adapter";
import StoryblokBlankSpace from "./adapters/storyblok-blank-space-adapter";
import StoryblokTitleAndText from "./adapters/storyblok-title-and-text";
import StoryblokTabbedContentAdapter from "./adapters/storyblok-tabbed-content-adapter";
import StoryblokObjectivesAdapter from "./adapters/storyblok-objectives-adapter";
import StoryblokJoinPrompt from "./adapters/storyblok-join-prompt";
import StoryblokQuoteBlock from "./adapters/storyblok-quote-block";
import StoryblokThankYouAd from "./adapters/storyblok-thank-you-ad";
import StoryblokHeaderWithVideo from "./adapters/storyblok-header-with-video";
import StoryblokShopComponent from "./storyblok-shop-component";
import StoryblokAnchor from "./adapters/storyblok-anchor-adapter";
import StoryblokPricingBlockAdapter from "./adapters/storyblok-pricing-block-adapter";
import StoryblokYoutubeVideoAdapter from "./adapters/storyblok-youtube-video-adapter";
import StoryblokCodeEmbed from "./adapters/storyblok-code-embed";

export default function useStoryblokComponents(componentName) {
  const STORYBLOK_COMPONENTS = {
    page: StoryblokPage,
    articleRoot: StoryblokArticleRootPage,
    article: StoryblokArticleComponent,
    shop: StoryblokShopComponent,
    textContent: StoryblokTextAdapter,
    image: StoryblokArticleInlineImageAdapter,
    homeHeader: StoryblokHomeHeaderAdapter,
    homeWhatWeDo: StoryblokHomeWhatWeDoAdapter,
    animatedHeader: StoryblokAnimatedHeaderAdapter,
    timeline: StoryblokTimelineAdapter,
    boardMembers: StoryblokBoardMembersAdapter,
    motmHomeBanner: StoryblokMotmHomeBannerAdapter,
    largeCtaBanner: StoryblokLargeCtaBannerAdapter,
    animatedGlobe: StoryblokAnimatedGlobe,
    textHeader: StoryblokTextHeaderAdapter,
    anchor: StoryblokAnchor,
    blankSpace: StoryblokBlankSpace,
    titleAndText: StoryblokTitleAndText,
    tabbedContent: StoryblokTabbedContentAdapter,
    objectives: StoryblokObjectivesAdapter,
    joinPrompt: StoryblokJoinPrompt,
    joinPromptArticle: StoryblokJoinPrompt,
    quoteBlock: StoryblokQuoteBlock,
    thankYouAd: StoryblokThankYouAd,
    headerWithVideo: StoryblokHeaderWithVideo,
    pricingBlock: StoryblokPricingBlockAdapter,
    youtubeVideo: StoryblokYoutubeVideoAdapter,
    codeEmbed: StoryblokCodeEmbed,
  };
  return useMemo(() => {
    return STORYBLOK_COMPONENTS[componentName];
  }, [componentName]);
}
