import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Link } from "gatsby";
import useInView from "react-cool-inview";
import { motion } from "framer-motion";

import LinkParser from "../utils/link-parser";
import LazyImage from "./lazy-image";
import CtaButton from "./ctaButton";

const CaseStudyOuterContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  ${(props) =>
    props.reverse &&
    css`
      justify-content: flex-end;
    `};
`;

const CaseStudyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 25px;
  flex-direction: row-reverse;
  width: 100%;
  ${(props) =>
    props.reverse &&
    css`
      flex-direction: row;
    `};
  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-top: 5px;
  }
`;

const CaseStudyTextContent = styled.div`
  max-width: 320px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  margin-right: 50px;
  margin-top: 50px;
  ${(props) =>
    props.reverse &&
    css`
      margin-left: 50px;
      margin-right: 0px;
    `};
  @media (max-width: 768px) {
    margin-right: 0px;
    margin-left: 0px;
    justify-content: flex-start;
    max-width: 100%;
  }
`;

const CaseStudySubtext = styled(motion.div)`
  font-family: Roboto Mono;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  letter-spacing: 0.25em;
  color: #ffffff;
  text-align: right;
  ${(props) =>
    props.reverse &&
    css`
      text-align: left;
      justify-content: flex-start;
    `};
  @media (max-width: 768px) {
    text-align: left;
    justify-content: flex-start;
  }
`;

const CaseStudyTitle = styled(motion.div)`
  font-family: Metropolis;
  font-weight: bold;
  font-size: 30px;
  line-height: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  color: #ffffff;
  text-align: right;
  margin-top: 10px;
  ${(props) =>
    props.reverse &&
    css`
      text-align: left;
    `};
  @media (max-width: 768px) {
    text-align: left;
    justify-content: flex-start;
  }
`;

const CaseStudyCTAContainer = styled(Link)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  text-decoration: none;
  margin-top: 25px;
  ${(props) =>
    props.reverse &&
    css`
      justify-content: flex-start;
    `};
  @media (max-width: 768px) {
    justify-content: flex-start;
  }
`;

const CaseStudyCta = styled.div`
  background-color: #fbf200;
  border-radius: 0px;
  border-width: 0px;
  color: #2b3445;
  font-size: 18px;
  line-height: 18px;
  padding: 10px 30px 10px 30px;
  max-width: 145px;
`;

const CaseStudyImageContainer = styled(motion.div)`
  width: 486px;
  margin-left: -30px;
`;

const CaseStudyImage = styled(LazyImage)``;

const CtaContainerOuter = styled(motion.div)``;

const ImageAnim = {
  hidden: {
    opacity: 0,
    x: 20,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      delay: 0.2,
      duration: 0.4,
      ease: "easeIn",
    },
  },
};

const ImageAnimReverse = {
  hidden: {
    opacity: 0,
    x: -20,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      delay: 0.2,
      duration: 0.4,
      ease: "easeIn",
    },
  },
};

const SubtextAnim = {
  hidden: {
    opacity: 0,
    y: -10,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.4,
      duration: 0.2,
      ease: "easeIn",
    },
  },
};

const TitleAnim = {
  hidden: {
    opacity: 0,
    y: -10,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.45,
      duration: 0.3,
      ease: "easeIn",
    },
  },
};

const CtaAnim = {
  hidden: {
    opacity: 0,
    y: -10,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.55,
      duration: 0.4,
      ease: "easeIn",
    },
  },
};

function WhatWeDoCaseStudyItem({
  caseStudyImage,
  caseStudyTitle,
  caseStudySubtext,
  enableCta,
  ctaButtonLink,
  ctaButtonText,
  reverse,
}) {
  const [isInView, setIsInView] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0.5,
    onEnter: ({ scrollDirection, entry, observe, unobserve }) => {
      setIsInView(true);
    },
  });
  return (
    <CaseStudyOuterContainer reverse={reverse} ref={ref}>
      <CaseStudyContainer reverse={reverse}>
        <CaseStudyImageContainer
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          variants={reverse ? ImageAnimReverse : ImageAnim}
        >
          <CaseStudyImage fluid={caseStudyImage} />
        </CaseStudyImageContainer>
        <CaseStudyTextContent reverse={reverse}>
          <CaseStudySubtext
            reverse={reverse}
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            variants={SubtextAnim}
          >
            {caseStudySubtext}
          </CaseStudySubtext>
          <CaseStudyTitle
            reverse={reverse}
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            variants={TitleAnim}
          >
            {caseStudyTitle}
          </CaseStudyTitle>
          {enableCta && (
            <CtaContainerOuter
              initial="hidden"
              animate={isInView ? "visible" : "hidden"}
              variants={CtaAnim}
            >
              <CaseStudyCTAContainer reverse={reverse}>
                <CtaButton
                  text={ctaButtonText}
                  link={LinkParser(ctaButtonLink)}
                  bold
                  backgroundColor={"transparent"}
                  backgroundColorHover={"transparent"}
                  color={"#FDE115"}
                  padding={"0px"}
                />
              </CaseStudyCTAContainer>
            </CtaContainerOuter>
          )}
        </CaseStudyTextContent>
      </CaseStudyContainer>
    </CaseStudyOuterContainer>
  );
}

export default WhatWeDoCaseStudyItem;
