import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Parallax } from "react-scroll-parallax";
import { useStaticQuery, graphql, Link } from "gatsby";
import useInView from "react-cool-inview";
import { RoughNotation, RoughNotationGroup } from "react-rough-notation";

import LazyImage from "../components/lazy-image";
import CtaButton from "../components/ctaButton";
import { getFluidGatsbyImage } from "../utils/gatsby-storyblok-image-fixed/";
import ArticleLinkBlock from "./articleLinkBlock";
import HomepageNewsRow from "./homepageNewsRow";
import YellowArrow from "../assets/images/icons/arrowYellow.svg";

const HeaderOuterContainer = styled.div``;

const HeaderContainer = styled.div`
  margin-top: -100px;
  width: 100%;
  height: 100vh;
  max-height: 1100px;
  display: flex;
  justify-content: center;
  background: #2c3546;
  position: relative;
  font-family: Metropolis;
  overflow: hidden;
`;

const ContentContainer = styled.div`
  padding-left: 150px;
  padding-right: 150px;
  width: 100%;
  max-width: 1900px;
  z-index: 5;
  display: flex;
  align-items: center;
  height: 100%;
  @media (max-width: 1100px) {
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    z-index: 5;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
  }
`;

const TextContainer = styled.div`
  z-index: 5;
  @media (max-width: 1100px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const TitleContainer = styled.div`
  margin-bottom: 25px;
  @media (max-width: 1100px) {
    margin-bottom: 25px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;

const Subtitle = styled(motion.div)`
  display: flex;

  font-size: 50px;
  align-items: center;
  font-weight: 600;
  line-height: 120px;

  color: #fbf200;

  @media (max-width: 1100px) {
    font-size: 42px;
    line-height: 85px;
    font-weight: 700;
  }
  @media (max-width: 440px) {
    font-size: 32px;
    line-height: 60px;
    font-weight: 700;
  }
`;

const Title = styled(motion.div)`
  font-family: besom;
  font-style: normal;
  font-weight: normal;
  font-size: 150px;
  display: flex;
  align-items: center;
  color: #ffffff;
  line-height: 70px;
  @media (max-width: 1100px) {
    font-family: besom;
    font-style: normal;
    font-weight: normal;
    font-size: 120px;
    display: flex;
    align-items: center;
    color: #ffffff;
    line-height: 70px;
  }
  @media (max-width: 440px) {
    font-family: besom;
    font-style: normal;
    font-weight: normal;
    font-size: 80px;
    display: flex;
    align-items: center;
    color: #ffffff;
    line-height: 50px;
  }
`;

const TitleUnderline = styled(motion.img)`
  min-width: 375px;
  height: 16px;
  margin-left: 50px;
  margin-top: 6px;
  @media (max-width: 1100px) {
    min-width: 320px;
    height: 16px;
    margin-left: 0;
    margin-top: -2px;
    position: absolute;
    top: 0;
    left: 0;
  }
  @media (max-width: 440px) {
    min-width: 230px;
    height: 16px;
    margin-left: 0px;
    margin-top: -2px;
  }
`;

const TitleUnderlineContainerOuter = styled.div`
  @media (max-width: 1100px) {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    max-width: 300px;
  }
  @media (max-width: 440px) {
    max-width: 230px;
  }
`;

const TitleUnderlineContainer = styled(motion.div)`
  width: 0px;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  position: relative;
  height: 16px;
`;

const ParagraphContainer = styled(motion.div)`
  max-width: 580px;
  font-weight: 600;
  font-weight: 600;
  width: 100%;
  font-size: 18px;
  line-height: 125%;
  display: flex;
  flex-direction: column;
  align-items: center;
  letter-spacing: 0.02em;
  color: #ffffff;

  @media (max-width: 1100px) {
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0;
    max-width: 390px;
    display: flex;
    font-weight: 600;
  }
  @media (max-width: 440px) {
    width: 90%;
    font-size: 18px;
  }
`;

const HomeSplashBackgroundContainer = styled.div`
  position: absolute;
  right: 0;
  height: 100vh;
  width: 80vw;
  transform: scale(1.1);
  @media (max-width: 1300px) {
    transform: scale(1);
  }
`;

const StyledParallax = styled(Parallax)`
  width: 100%;
  height: 100%;
`;

const HomeSplashBackground = styled(motion.div)`
  height: 100%;
  width: 100%;
  background: #2c3445;
  background-image: linear-gradient(
      90deg,
      rgba(45, 53, 71, 1) 0%,
      rgba(45, 53, 71, 0) 25%
    ),
    url(${(props) => props.imageUrl});
  background-position: right;
  background-size: cover;
  background-position-x: right;
  background-position-y: top;
  background-repeat: no-repeat;
  z-index: 0;
  transition: 0ms;
  @media (max-height: 1020px) {
    background-size: container;
  }
  @media (max-width: 2200px) {
    background-position-x: left;
    background-position-x: 30%;
  }
  @media (max-width: 1300px) {
    position: absolute;
    right: 0;
    height: 100vh;
    width: 80vw;
    background: #2c3445;
    background-image: linear-gradient(
        90deg,
        rgba(45, 53, 71, 1) 0%,
        rgba(45, 53, 71, 0) 25%
      ),
      url(${(props) => props.imageUrl});

    background-size: cover;
    background-position-x: left;
    background-position-y: top;
    background-repeat: no-repeat;
    background-position-x: 20%;
  }
  @media (max-width: 1100px) {
    position: absolute;
    right: 0;
    height: 100vh;
    background: #2c3445;
    background-size: cover;
    background-position-x: right;
    background-position-y: top;
    background-repeat: no-repeat;
    width: 100vw;
    background-image: linear-gradient(
        90deg,
        rgba(45, 53, 71, 0.9472163865546218) 0%,
        rgba(45, 53, 71, 0.804359243697479) 100%
      ),
      url(${(props) => props.mobileImageUrl});
    background-position: center center;
  }
`;

const ParagraphItem = styled(motion.div)`
  margin: 10px 0px;
`;

const ParagraphItemDesktop = styled(motion.div)`
  margin: 10px 0px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const ParagraphItemMobile = styled(motion.div)`
  margin: 10px 0px;
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

const SubtitleContainer = styled.div`
  display: flex;
`;

const SubtitleTwo = styled(motion.div)`
  display: flex;
  color: #ffffff;
  font-size: 50px;
  align-items: center;
  font-weight: 600;
  line-height: 120px;
  margin-left: 10px;

  @media (max-width: 1100px) {
    font-size: 42px;
    line-height: 85px;
    font-weight: 700;
  }
  @media (max-width: 440px) {
    font-size: 32px;
    line-height: 60px;
    font-weight: 700;
  }
`;

const NewsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #f8f8f8;
  z-index: 5;
  padding-bottom: 50px;
`;

const News = styled(motion.div)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: -70px;
  max-width: 1200px;
  width: 100%;
  z-index: 5;
  @media (max-height: 610px) {
    margin-top: 30px;
  }
`;

const NewsRowTwo = styled(motion.div)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  width: 100%;
  z-index: 5;
  @media (max-height: 610px) {
    margin-top: 30px;
  }
`;

const PostOuterContainer = styled(motion.div)`
  width: 348px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  z-index: 5;

  &:nth-of-type(3) {
    @media (max-width: 1164px) {
      display: none;
    }
    @media (max-width: 775px) {
      display: block !important;
    }
  }
`;

const NewsCtaContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
`;

const CtaButtonArrowContainer = styled.div`
  height: 100%;
  margin-left: 10px;
  margin-top: 2px;
`;

const NewsSecondRowContainer = styled.div`
  @media (max-width: 1164px) {
    display: none;
  }
`;

const HeaderCtaContainer = styled(motion.div)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  @media (max-width: 1100px) {
    justify-content: center;
  }
`;

const TitleAnim = {
  hidden: {
    opacity: 0,
    x: 0,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      delay: 0.4,
      duration: 0.2,
    },
  },
};

const TitleTwoAnim = {
  hidden: {
    opacity: 0,
    x: 0,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      delay: 0.8,
      duration: 0.2,
    },
  },
};

const TitleBoldAnim = {
  hidden: {
    opacity: 0,
    x: -20,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      delay: 0.95,
      duration: 0.7,
    },
  },
};

const LineAnim = {
  hidden: {
    width: 0,
    minWidth: 0,
  },
  visible: {
    width: "450px",
    minWidth: "450px",
    transition: {
      delay: 0.8,
      duration: 1.1,
      ease: "backIn",
    },
  },
};

const ParagraphAnim = {
  hidden: {
    opacity: 1,
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
      delayChildren: 1.2,
    },
  },
};

const ParagraphItemAnim = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
    },
  },
};

const BackgroundImageAnim = {
  hidden: {
    opacity: 0,
    x: 20,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      delay: 1.6,
      duration: 0.7,
    },
  },
};

const CtaAnim = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 1.4,
      duration: 0.2,
    },
  },
};

const NewsAnim = {
  hidden: {
    opacity: 1,
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.15,
      delayChildren: 1.8,
    },
  },
};

const NewsItemAnim = {
  hidden: { opacity: 0, y: 40 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.4,
    },
  },
};

function HomeHeader({
  image,
  imageUrl,
  underlineImg,
  underlineImageUrl,
  mobileImageUrl,
}) {
  const data = useStaticQuery(graphql`
    query FrontpageArticlesQuery {
      articles: allStoryblokEntry(
        filter: { field_component: { eq: "article" } }
        sort: { fields: [field_dateWritten_string], order: DESC }
      ) {
        edges {
          node {
            name
            full_slug
            field_dateWritten_string
            content
          }
        }
      }
    }
  `);

  const [isInView, setIsInView] = useState(false);
  const [showLine, setShowLine] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowLine(true);
    }, 2000);
  }, []);

  const { ref, inView } = useInView({
    threshold: 0,
    onEnter: ({ scrollDirection, entry, observe, unobserve }) => {
      setIsInView(true);
    },
  });

  return (
    <HeaderOuterContainer>
      <HeaderContainer ref={ref}>
        <ContentContainer>
          <TextContainer>
            <TitleContainer>
              <SubtitleContainer>
                <Subtitle
                  initial="hidden"
                  animate={isInView ? "visible" : "hidden"}
                  variants={TitleAnim}
                >
                  Together,
                </Subtitle>
                <SubtitleTwo
                  initial="hidden"
                  animate={isInView ? "visible" : "hidden"}
                  variants={TitleTwoAnim}
                >
                  we're
                </SubtitleTwo>
              </SubtitleContainer>
              <Title
                initial="hidden"
                animate={isInView ? "visible" : "hidden"}
                variants={TitleBoldAnim}
              >
                Stronger
              </Title>
              <TitleUnderlineContainerOuter>
                <TitleUnderlineContainer
                  initial="hidden"
                  animate={isInView ? "visible" : "hidden"}
                  variants={LineAnim}
                >
                  <TitleUnderline src={underlineImageUrl} />
                </TitleUnderlineContainer>
              </TitleUnderlineContainerOuter>
            </TitleContainer>
            <ParagraphContainer
              initial="hidden"
              animate={isInView ? "visible" : "hidden"}
              variants={ParagraphAnim}
            >
              <ParagraphItemDesktop variants={ParagraphItemAnim}>
                We aim to improve the club, ground, local area and community,
                <RoughNotation
                  type="underline"
                  show={showLine}
                  color={"#FDE115"}
                  iterations={2}
                  padding={0}
                  animationDuration={300}
                  padding={[5, 0, 0, 0]}
                >
                  <span> for everyone.</span>
                </RoughNotation>
              </ParagraphItemDesktop>
              <ParagraphItemMobile variants={ParagraphItemAnim}>
                We aim to improve the club, ground, local area and community,
                for everyone.
              </ParagraphItemMobile>
              <ParagraphItem variants={ParagraphItemAnim}>
                By joining the Trust, you help us to continue our work, achieve
                our objectives, and a get whole host of exclusive benefits.
              </ParagraphItem>
              <HeaderCtaContainer
                initial="hidden"
                animate={isInView ? "visible" : "hidden"}
                variants={CtaAnim}
              >
                <CtaButton
                  text={"Learn More"}
                  link={"/membership"}
                  bold
                  backgroundColor={"transparent"}
                  backgroundColorHover={"transparent"}
                  color={"#FDE115"}
                  padding={"0px"}
                />
              </HeaderCtaContainer>
            </ParagraphContainer>
          </TextContainer>
        </ContentContainer>
        <HomeSplashBackgroundContainer>
          <StyledParallax
            y={[-20, 20]}
            styleInner={{ width: "100%", height: "100%" }}
          >
            <HomeSplashBackground
              initial="hidden"
              animate={isInView ? "visible" : "hidden"}
              variants={BackgroundImageAnim}
              imageUrl={imageUrl}
              mobileImageUrl={mobileImageUrl}
            />
          </StyledParallax>
        </HomeSplashBackgroundContainer>
      </HeaderContainer>
      <NewsContainer>
        <News
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          variants={NewsAnim}
        >
          {data.articles.edges.slice(0, 3).map((article, i) => {
            let articleData = JSON.parse(article.node.content);
            let articleImage = getFluidGatsbyImage(articleData.thumbnailImage, {
              maxWidth: 800,
            });

            return (
              <PostOuterContainer key={i} variants={NewsItemAnim}>
                <ArticleLinkBlock
                  link={article.node.full_slug}
                  thumbnail={articleImage}
                  title={article.node.name}
                  snippet={articleData.postSnippet}
                />
              </PostOuterContainer>
            );
          })}
        </News>
        <NewsSecondRowContainer>
          <HomepageNewsRow data={data.articles.edges} />
        </NewsSecondRowContainer>

        <NewsCtaContainer>
          <CtaButton
            text={"See All Articles"}
            link={"/articles"}
            color={"#fff"}
            backgroundColor={"#2D3445"}
            backgroundColorHover={"#191B3C"}
          />
        </NewsCtaContainer>
      </NewsContainer>
    </HeaderOuterContainer>
  );
}

export default HomeHeader;
