import React from "react";

import { getFluidGatsbyImage } from "../../../utils/gatsby-storyblok-image-fixed";
import MotmHomeBanner from "../../motmHomeBanner";

function StoryblokMotmHomeBannerAdapter({ image, ...rest }) {
  let fluidImage = getFluidGatsbyImage(image, {
    maxWidth: 900,
  });
  return <MotmHomeBanner image={fluidImage} {...rest} />;
}

export default StoryblokMotmHomeBannerAdapter;
