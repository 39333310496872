import React, { useState } from "react";
import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import { Link, useStaticQuery, graphql } from "gatsby";

import { getFluidGatsbyImage } from "../utils/gatsby-storyblok-image-fixed";
import RootPageArticleLink from "./rootPageArticleLink";
import withLocation from "../utils/with-location";
import CtaButton from "./ctaButton";

const RecentArticlesOuterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fdf8f8;
  padding: 0px 20px;
  padding-top: 100px;
  margin-top: 50px;
  flex-direction: column;
  @media (max-width: 768px) {
    padding-top: 40px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 30px;
  }
`;

const RecentArticlesContainer = styled.div`
  max-width: 1300px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(390px, 1fr));
  grid-gap: 1rem;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 37px;
  line-height: 28px;
  margin-bottom: 75px;
  @media (max-width: 768px) {
    margin-bottom: 40px;
  }
`;

const CtaContainer = styled.div`
  margin-bottom: 50px;
  margin-top: -20px;
  @media (max-width: 768px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  @media (max-width: 1245px) {
    ${(props) =>
      props.hide &&
      css`
        display: none;
      `}
  }
  @media (max-width: 837px) {
    display: block;
  }
`;

function RecentArticles({ location }) {
  const data = useStaticQuery(graphql`
    query RecentArticles {
      recentArticles: allStoryblokEntry(
        filter: { field_component: { eq: "article" } }
        sort: { fields: [field_dateWritten_string], order: DESC }
      ) {
        edges {
          node {
            name
            full_slug
            field_dateWritten_string
            content
          }
        }
      }
    }
  `);

  return (
    <RecentArticlesOuterContainer>
      <Title>Latest Articles</Title>
      <RecentArticlesContainer>
        {data.recentArticles.edges.slice(0, 3).map((recentArticle, i) => {
          let article = JSON.parse(recentArticle.node.content);
          let fluidPostImage = getFluidGatsbyImage(article.thumbnailImage, {
            maxWidth: 385,
          });
          return (
            <Container hide={i === 2 ? true : false}>
              <RootPageArticleLink
                link={"/" + recentArticle.node.full_slug}
                thumbnail={fluidPostImage}
                postDate={article.dateWritten}
                title={recentArticle.node.name}
                snippet={article.postSnippet}
              />
            </Container>
          );
        })}
      </RecentArticlesContainer>
      <CtaContainer>
        <CtaButton
          text={"See All Articles"}
          link={"/articles"}
          backgroundColor={"#FDF8F8"}
          backgroundColorHover={"#FDF8F8"}
          color={"#2D3445"}
        />
      </CtaContainer>
    </RecentArticlesOuterContainer>
  );
}

export default withLocation(RecentArticles);
