import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Link } from "gatsby";
import useInView from "react-cool-inview";
import { motion } from "framer-motion";

import LazyImage from "./lazy-image";
import { getFluidGatsbyImage } from "../utils/gatsby-storyblok-image-fixed";

import MailIcon from "../assets/images/icons/emailIcon.svg";
import TwitterIcon from "../assets/images/icons/twitterIcon.svg";

const BoardMemberCardContainer = styled.div`
  height: 400px;
  min-width: 310px;
  width: 310px;
  background-color: #fff;
  border-radius: 15px;
  margin-right: 20px;
  position: relative;
  overflow: hidden;
  display: flex;
  user-select: none;
  align-items: flex-end;
  @media (max-width: 768px) {
    height: 340px;
    width: 250px;
    min-width: 250px;
  }
`;

const BackgroundImageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #2c3445;
`;

const Gradient = styled.div`
  background-image: linear-gradient(transparent, #2c3445);
  opacity: 0.9;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
`;

const BackgroundImage = styled(LazyImage)`
  width: 100%;
  height: 100%;
`;

const ContentContainer = styled.div`
  z-index: 4;
  padding: 10px 20px;
  color: #fff;
`;

const Date = styled.div`
  font-size: 15px;
  line-height: 12px;
  color: #e6e5e5;
  font-weight: 500;
`;

const Name = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
`;

const Position = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
`;

const ToggleButton = styled.div`
  position: absolute;
  top: 22px;
  right: 18px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  background-color: #2c3445;
  cursor: pointer;
  transition: background 0.35s ease-out;
  z-index: 12;
  &,
  &:before,
  &:after {
    transition-delay: 0.1s;
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 20px;
    height: 2px;
    background-color: #fff;
    will-change: transform;
    transition: 0.25s ease-out;
    transition-property: transform, background;
  }

  &:after {
    transform: translate(-50%, -50%) rotate(90deg);
  }

  ${(props) =>
    props.close &&
    css`
      background-color: transparent;

      &,
      &:before,
      &:after {
        transition-delay: 0.35s;
      }

      &:before,
      &:after {
        background-color: #fde115;
      }

      &:before {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &:after {
        transform: translate(-50%, -50%) rotate(135deg);
      }
    `};
`;

const AdditionalContent = styled(motion.div)`
  height: 100%;
  width: calc(100% - 40px);
  background-color: #2c3445;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  flex-direction: column;
  color: #fff;
  max-height: calc(100% - 40px);
  overflow-y: auto;
`;

const Title = styled(motion.div)`
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  color: #fff;
  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 18px;
  }
`;

const PositionExpanded = styled(motion.div)`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 6px;
  color: #fff;
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 14px;
  }
`;

const Bio = styled(motion.div)`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 26px;
  color: #fff;
  @media (max-width: 768px) {
    font-size: 11px;
    line-height: 14px;
    margin-bottom: 11px;
  }
`;

const Email = styled(motion.a)`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #000;
  text-decoration: none;
  color: #fff;
  @media (max-width: 768px) {
    font-size: 10px;
    line-height: 12px;
  }
`;

const AdditionalContentAnim = {
  hidden: {
    y: "400px",
  },
  visible: {
    y: 0,
    transition: {
      ease: "easeOut",
    },
  },
};

const TitleAnim = {
  hidden: {
    y: 20,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      ease: "easeOut",
      delay: 0.3,
    },
  },
};

const PositionAnim = {
  hidden: {
    y: 20,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      ease: "easeOut",
      delay: 0.35,
    },
  },
};

const BioAnim = {
  hidden: {
    y: 20,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      ease: "easeOut",
      delay: 0.4,
    },
  },
};

const EmailAnim = {
  hidden: {
    y: 20,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      ease: "easeOut",
      delay: 0.45,
    },
  },
};

function BoardMemberCard({ data, leftAlign }) {
  let boardMemberImage = getFluidGatsbyImage(data.image, {
    maxWidth: 800,
  });
  const [isInView, setIsInView] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0.5,
    onEnter: ({ scrollDirection, entry, observe, unobserve }) => {
      setIsInView(true);
    },
  });
  return (
    <BoardMemberCardContainer>
      <ContentContainer>
        <Date>Since {data.joinYear}</Date>
        <Name>{data.name}</Name>
        <Position>{data.position}</Position>
      </ContentContainer>
      <BackgroundImageContainer>
        <Gradient />
        <BackgroundImage fluid={boardMemberImage} />
      </BackgroundImageContainer>
      <AdditionalContent
        initial="hidden"
        animate={showDetails ? "visible" : "hidden"}
        variants={AdditionalContentAnim}
      >
        <Title
          initial="hidden"
          animate={showDetails ? "visible" : "hidden"}
          variants={TitleAnim}
        >
          {data.name}
        </Title>
        <PositionExpanded
          initial="hidden"
          animate={showDetails ? "visible" : "hidden"}
          variants={PositionAnim}
        >
          {data.position} since {data.joinYear}
        </PositionExpanded>
        <Bio
          initial="hidden"
          animate={showDetails ? "visible" : "hidden"}
          variants={BioAnim}
        >
          {data.bio}
        </Bio>
        <Email
          initial="hidden"
          animate={showDetails ? "visible" : "hidden"}
          variants={EmailAnim}
          href={"mailto:" + data.email}
        >
          {data.email}
        </Email>
      </AdditionalContent>
      <ToggleButton
        onClick={() => setShowDetails((state) => !state)}
        close={showDetails}
      />
    </BoardMemberCardContainer>
  );
}

export default BoardMemberCard;
