import React from "react";
import loadable from "@loadable/component";

const AnimatedGlobe = loadable(() => import("../../animatedGlobe"));

function StoryblokAnimatedGlobe({ locations, ...rest }) {
  let parsedLocations = [];
  locations.map((location, i) => {
    let locationParsed = {
      id: "marker" + i,
      city: location.title,
      color: "#FDE115",
      coordinates: [
        parseFloat(location.lattitude),
        parseFloat(location.longitude),
      ],
      value: 100,
    };
    parsedLocations.push(locationParsed);
  });
  if (typeof window !== "undefined") {
    return <AnimatedGlobe globeData={parsedLocations} {...rest} />;
  } else {
    return null;
  }
}

export default StoryblokAnimatedGlobe;
