import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import useInView from "react-cool-inview";
import { render } from "storyblok-rich-text-react-renderer";
import { Parallax } from "react-scroll-parallax";

const HeaderContainerOuter = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: 125px;
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-height: 900px;
  position: relative;
  @media (max-width: 500px) {
    padding-top: 100px;
    padding-bottom: 40px;
  }
`;

const HeaderBackgroundLayer = styled(motion.div)`
  background-color: #2c3445;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: visible;
  width: calc(100% - 40px);
  margin-left: 20px;
  margin-top: -80px;
  max-height: 900px;
  @media (max-width: 670px) {
    width: calc(100% - 15px);
    margin-left: 7.5px;
    margin-top: -95px;
    max-height: unset;
  }
`;

const HeaderContainerInner = styled(motion.div)`
  max-width: 1100px;
  width: 100%;
  z-index: 2;
  @media (max-width: 1290px) {
    margin: 0px 50px;
  }
  @media (max-width: 650px) {
    margin: 0px 20px;
  }
`;

const Subtitle = styled(motion.div)`
  font-size: 14px;
  color: #fff;
  margin-bottom: 22px;
  letter-spacing: 3.5px;
  text-transform: uppercase;
`;

const Title = styled(motion.h1)`
  font-size: 86px;
  line-height: 96px;
  color: #fff;
  margin-bottom: 32px;
  letter-spacing: -2.6px;
  font-weight: 700;
  max-width: 900px;
  font-family: "Poppins";
  margin-bottom: 55px;
  b {
    color: #fde115;
  }
  @media (max-width: 1100px) {
    font-size: 70px;
    line-height: 77px;
    max-width: 70%;
  }
  @media (max-width: 1016px) {
    max-width: 100%;
  }
  @media (max-width: 900px) {
    font-size: 61px;
    line-height: 71px;
    max-width: 80%;
  }
  @media (max-width: 900px) {
    max-width: 80%;
  }
  @media (max-width: 800px) {
    max-width: 90%;
  }
  @media (max-width: 768px) {
    font-size: 42px;
    line-height: 44px;
    margin-bottom: 32px;
  }
`;

const Paragraph = styled(motion.div)`
  font-weight: 500;
  font-size: 24px;
  line-height: 1.5;
  color: #fff;
  max-width: 651px;
  margin-bottom: 30px;
  @media (max-width: 800px) {
    font-size: 21px;
    max-width: 80%;
  }
  @media (max-width: 560px) {
    font-size: 18px;
    max-width: 90%;
  }
`;

const BackgroundAnim = {
  hidden: {
    width: 0,
    height: "100%",
    transition: {
      delay: 0,
      duration: 0.2,
    },
  },
  visible: {
    width: "100%",
    height: "100%",
    transition: {
      delay: 0.2,
      duration: 0.3,
      ease: "easeIn",
    },
  },
};

const SubtitleAnim = {
  hidden: {
    opacity: 0,
    y: 30,
    transition: {
      delay: 0,
      duration: 0.2,
    },
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.6,
    },
  },
};

const TitleAnim = {
  hidden: {
    opacity: 0,
    y: 30,
    transition: {
      delay: 0,
      duration: 0.2,
    },
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.7,
    },
  },
};

const ParagraphAnim = {
  hidden: {
    opacity: 0,
    y: 30,
    transition: {
      delay: 0,
      duration: 0.2,
    },
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.8,
    },
  },
};

function TextHeader({ subtitle, title, paragraph }) {
  const [isInView, setIsInView] = useState(true);
  const { ref, inView } = useInView({
    threshold: 0,
    onEnter: ({ scrollDirection, entry, observe, unobserve }) => {
      setIsInView(true);
    },
  });
  return (
    <HeaderContainer>
      <HeaderContainerOuter>
        <HeaderContainerInner>
          <Subtitle
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            variants={SubtitleAnim}
          >
            {subtitle}
          </Subtitle>

          <Title
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            variants={TitleAnim}
          >
            {render(title)}
          </Title>

          <Paragraph
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            variants={ParagraphAnim}
            dangerouslySetInnerHTML={paragraph}
          />
        </HeaderContainerInner>
        <HeaderBackgroundLayer
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          variants={BackgroundAnim}
        />
      </HeaderContainerOuter>
    </HeaderContainer>
  );
}

export default TextHeader;
