import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import Skeleton from "react-loading-skeleton";
import useDimensions from "react-use-dimensions";
import useInView from "react-cool-inview";
import { Parallax } from "react-scroll-parallax";

import LazyImage from "../components/lazy-image";

const OuterContainer = styled.div`
  max-width: 960px;
  margin: 0 auto;
  padding: 0 1.0875rem 1.45rem;
`;

const HeaderImage = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  z-index: 5;
  overflow: hidden;
`;

const ImageWrapper = styled.div`
  grid-column: 1 / -1;
  @media (min-width: 1180px) {
    grid-column: span 8 / -1;
  }
  @media (min-width: 768px) {
    grid-column: span 10 / -1;
  }
  position: relative;
`;

const SkeletonContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  display: none;
`;

const FeaturedImage = styled(LazyImage)`
  z-index: 5;
  background-color: #e4d9d5;
`;

const HeaderIntro = styled.div`
  z-index: 7;
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  @media (min-width: 1180px) {
    margin-top: -120px;
  }
  @media (min-width: 768px) {
    margin-top: -80px;
  }
  @media (max-width: 768px) {
    display: block;
    margin-top: -70px;
    width: 95%;
    margin-left: 2.5%;
  }
`;

const HeaderTextContainer = styled.div`
  grid-column: -1;
  background-color: #ffffff;
  border-radius: 4px;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  @media (min-width: 1180px) {
    grid-column: 2 / span 7;
  }
  @media (min-width: 768px) {
    grid-column: span 9;
  }
  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const Title = styled(motion.h1)`
  font-weight: 800;
  font-size: 48px;
  line-height: 55px;
  color: #2c3445;
  margin-bottom: 5px;
  @media (max-width: 900px) {
    font-size: 38px;
    line-height: 48px;
  }
  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 40px;
  }
`;

const Subtitle = styled(motion.h2)`
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  color: #36313d;
  span {
    font-weight: 600;
  }
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 30px;
  }
`;

const InlineImg = styled.div`
  max-width: 100%;
  max-height: 100%;
`;

const ImageAnimationLayer = styled(motion.div)`
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 6;
  position: absolute;
  top: 0;
  left: 0;
`;

const StyledParallax = styled(Parallax)`
  width: 100%;
  height: 100%;
  z-index: 2;
`;

const ImageAnimLayer = {
  hidden: { width: "100%" },
  visible: {
    width: "0%",
    transition: {
      duration: 0.4,
      delay: 0.6,
    },
  },
};

const TitleAnim = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.4,
      delay: 0.35,
    },
  },
};

const SubtitleAnim = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.4,
      delay: 0.4,
    },
  },
};

function ArticleHeader({ featuredImage, title, author, dateWritten }) {
  const [ref, { height }] = useDimensions();
  const [isInView, setIsInView] = useState(true);

  const { headerRef, inView } = useInView({
    threshold: 0.1,
    onEnter: ({ scrollDirection, entry, observe, unobserve }) => {
      setIsInView(true);
    },
  });
  return (
    <OuterContainer>
      <HeaderImage ref={headerRef}>
        <ImageWrapper ref={ref}>
          <ImageAnimationLayer
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            variants={ImageAnimLayer}
          />
          <StyledParallax
            y={[-20, 20]}
            styleInner={{ width: "100%", height: "100%" }}
          >
            <FeaturedImage fluid={featuredImage} />
          </StyledParallax>
          <SkeletonContainer>
            <Skeleton height={height - 5} />
          </SkeletonContainer>
        </ImageWrapper>
      </HeaderImage>

      <HeaderIntro>
        <HeaderTextContainer>
          <Title
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            variants={TitleAnim}
          >
            {title}
          </Title>
          <Subtitle
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            variants={SubtitleAnim}
          >
            Words by <span>{author}</span> | {dateWritten}
          </Subtitle>
        </HeaderTextContainer>
      </HeaderIntro>
    </OuterContainer>
  );
}

export default ArticleHeader;
