import React from "react";

import StoryblokBloks from "./storyblok-bloks";
import ClientOnly from "../../utils/clientOnly";

function StoryblokPage(props) {
  return (
    <ClientOnly>
      <StoryblokBloks {...props} />
    </ClientOnly>
  );
}

export default StoryblokPage;
