import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import useInView from "react-cool-inview";
import { render } from "storyblok-rich-text-react-renderer";

const QuoteBlockOuterContainer = styled.div`
  width: 100vw;
  padding: 60px 45px;
  margin: 75px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.textColor};
  @media (max-width: 768px) {
    padding: 30px 0px;
    margin: 30px 0px;
  }
`;

const QuoteOuter = styled.div`
  display: flex;
  justify-content: flex-start;
  max-width: 960px;
  width: 100%;
  flex-direction: column;
  padding: 0px 20px;
`;

const Quote = styled(motion.div)`
  font-size: 40px;
  line-height: 50px;
  letter-spacing: 3px;
  font-weight: 600;
  width: 90%;
  text-align: left;
  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 1px;
  }
`;

const Quotee = styled(motion.div)`
  font-size: 18px;
  line-height: 18px;
`;

const QuoteAnim = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.4,
      ease: "easeIn",
    },
  },
};

const QuoteeAnim = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.5,
      ease: "easeIn",
    },
  },
};

function QuoteBlock({ text, quotee, backgroundColor, textColor }) {
  const [isInView, setIsInView] = useState(false);

  const { ref, inView } = useInView({
    threshold: 0.25,
    onEnter: ({ scrollDirection, entry, observe, unobserve }) => {
      setIsInView(true);
    },
  });
  return (
    <QuoteBlockOuterContainer
      backgroundColor={backgroundColor}
      textColor={textColor}
      ref={ref}
    >
      <QuoteOuter>
        <Quote
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          variants={QuoteAnim}
        >
          {render(text)}
        </Quote>
        <Quotee
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          variants={QuoteeAnim}
        >
          {quotee}
        </Quotee>
      </QuoteOuter>
    </QuoteBlockOuterContainer>
  );
}

export default QuoteBlock;
