import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { render } from "storyblok-rich-text-react-renderer";
import { Parallax } from "react-scroll-parallax";
import ReactPlayer from "react-player/youtube";
import useOnclickOutside from "react-cool-onclickoutside";

import PlayIcon from "../assets/images/icons/playArrow.svg";
import CtaButton from "../components/ctaButton.jsx";
import CloseIcon from "../assets/images/icons/close.svg";

const HeaderContainerOuter = styled.div`
  width: 100%;
  margin-top: -100px;
  position: relative;
`;

const HeaderContainer = styled.div`
  height: 100vh;
  background-color: #88e1fd;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  overflow: hidden;
  position: relative;
  min-height: 750px;
  @media (max-width: 500px) {
    height: 680px;
    min-height: unset;
  }
`;

const HeaderContainerInner = styled.div`
  width: 90%;
  max-width: 1300px;
  z-index: 2;
  @media (max-width: 750px) {
    margin-top: -100px;
  }
  @media (max-width: 500px) {
    margin-top: 0px;
  }
  @media (max-width: 350px) {
    margin-top: 50px;
  }
`;

const VideoContainerOuter = styled(motion.div)`
  width: 100%;
  max-width: 90%;
  margin-left: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -100px;
  z-index: 5;
  @media (max-height: 700px) {
    margin-top: -50px;
  }
  @media (max-height: 610px) {
    margin-top: 50px;
  }
`;

const VideoContainer = styled(motion.div)`
  max-width: 1100px;
  width: 100%;
  height: 600px;
  border-radius: 13px;
  cursor: pointer;
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.15),
      rgba(0, 0, 0, 0.15)
    ),
    url(${(props) => props.image});
  background-position: 0px 0px, 0% 50%;
  background-size: auto, cover;
  box-shadow: 0 30px 60px -20px rgba(33, 55, 75, 0.29);
  -webkit-filter: contrast(122%);
  filter: contrast(122%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  @media (max-width: 1100px) {
    height: 500px;
  }

  @media (max-width: 800px) {
    height: 400px;
  }
  @media (max-width: 768px) {
    margin-bottom: 50px;
  }

  @media (max-width: 500px) {
    height: 300px;
    margin-top: 50px;
  }

  @media (max-width: 450px) {
    height: 250px;
    margin-top: 20px;
  }
  @media (max-width: 350px) {
    height: 200px;
    margin-top: 150px;
  }
`;

const PlayIconContainer = styled(motion.div)`
  z-index: 10;
  width: 70px;
  height: 70px;
  margin: auto;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2c3445;
  cursor: pointer;
  will-change: transform;
`;

const PlayIconContainerInner = styled.div`
  padding-left: 4px;
  height: 70px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  will-change: transform;
`;

const Title = styled(motion.h1)`
  color: #232530;
  font-size: 86px;
  line-height: 96px;
  letter-spacing: -2.6px;
  font-weight: 700;
  font-family: "Poppins";
  max-width: 661px;
  @media (max-width: 850px) {
    font-size: 66px;
    line-height: 72px;
  }
  @media (max-width: 450px) {
    font-size: 44px;
    line-height: 52px;
  }
`;

const Subtitle = styled(motion.h2)`
  font-weight: 500;
  font-size: 22px;
  line-height: 1.5;
  color: #232530;
  max-width: 661px;
  margin-bottom: 30px;
  @media (max-width: 750px) {
    font-size: 18px;
    max-width: 90%;
  }
`;

const HomeSplashBackgroundContainer = styled.div`
  position: absolute;
  z-index: 0;
  right: 0;
  height: 100vh;
  overflow: hidden;
  width: 80vw;
  transform: scale(1.1);
  top: 0;
  min-height: 750px;
  @media (max-width: 1300px) {
    transform: scale(1);
  }
  @media (max-width: 500px) {
    height: 680px;
    min-height: unset;
  }
`;

const SplashBackgroundInnerContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: hidden;
`;

const HomeSplashBackground = styled(motion.div)`
  height: 100%;
  width: 100%;
  background: #88e1fd;
  background-image: linear-gradient(
      90deg,
      rgb(136 225 253) 0%,
      rgba(45, 53, 71, 0) 25%
    ),
    url(${(props) => props.imageUrl});

  background-size: contain;
  background-repeat: no-repeat;
  z-index: 0;
  transition: 0ms;
  background-position: center right;
  @media (max-width: 1300px) {
    position: absolute;
    right: 0;
    height: 100vh;
    width: 80vw;

    background: #88e1fd;
    background-image: linear-gradient(
        90deg,
        rgb(136 225 253) 0%,
        rgba(45, 53, 71, 0) 25%
      ),
      url(${(props) => props.imageUrl});
  }
  @media (max-width: 1100px) {
    position: absolute;
    right: 0;
    height: 100vh;
    background: #88e1fd;

    width: 100vw;
    background-image: linear-gradient(
        90deg,
        rgb(136 225 253) 0%,
        rgb(136 225 253 / 70%) 100%
      ),
      url(${(props) => props.mobileImageUrl});
  }
  @media (max-width: 750px) {
    background-position: center center;
    background-size: cover;
    height: 100%;
  }
`;

const CtaButtonContainer = styled(motion.div)`
  width: 220px;
`;

const VideoPlayerContainerOuter = styled(motion.div)`
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: #000000bf;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

const VideoPlayerContainer = styled(motion.div)`
  width: 640px;
  height: 360px;
  position: relative;
  background-color: #232530;
`;

const CloseIconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin-top: -30px;
  margin-right: -30px;
  cursor: pointer;
`;

const PlayAnim = {
  hidden: {
    width: "70px",
    height: "70px",
    transition: {
      delay: 0,
      duration: 0.2,
    },
  },
  visible: {
    width: "80px",
    height: "80px",
  },
};

const TitleAnim = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.1,
      duration: 0.4,
    },
  },
};

const SubtitleAnim = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.3,
      duration: 0.4,
    },
  },
};

const CtaAnim = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.4,
      duration: 0.5,
    },
  },
};

const BackgroundImageAnim = {
  hidden: {
    opacity: 0,
    x: 20,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      delay: 0.6,
      duration: 0.7,
    },
  },
};

const VideoHomeAnim = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.9,
      duration: 0.5,
    },
  },
};

const VideoContainerAnim = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,

    transition: {
      duration: 0.7,
    },
  },
};

const VideoPlayerAnim = {
  hidden: {
    opacity: 0,
    y: -20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.4,
      delay: 0.5,
    },
  },
};

function HeaderWithVideo({
  title,
  subtitle,
  videoThumbnailImg,
  backgroundImage,
  ctaText,
}) {
  const [playHovered, setPlayHovered] = useState(false);
  const [isInView, setIsInView] = useState(true);
  const [videoOpen, setVideoOpen] = useState(false);

  const ref = useOnclickOutside(() => {
    setVideoOpen(false);
  });

  return (
    <HeaderContainerOuter>
      {videoOpen && (
        <VideoPlayerContainerOuter
          initial="hidden"
          animate={videoOpen ? "visible" : "hidden"}
          variants={VideoContainerAnim}
        >
          <VideoPlayerContainer
            ref={ref}
            initial="hidden"
            animate={videoOpen ? "visible" : "hidden"}
            variants={VideoPlayerAnim}
          >
            <CloseIconContainer onClick={() => setVideoOpen(false)}>
              <CloseIcon width={"20px"} fill={"#fff"} />
            </CloseIconContainer>
            <ReactPlayer
              controls={true}
              url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
            />
          </VideoPlayerContainer>
        </VideoPlayerContainerOuter>
      )}
      <HeaderContainer>
        <HeaderContainerInner>
          <Title
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            variants={TitleAnim}
          >
            {title}
          </Title>
          <Subtitle
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            variants={SubtitleAnim}
          >
            {render(subtitle)}
          </Subtitle>
          <CtaButtonContainer
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            variants={CtaAnim}
          >
            <CtaButton
              text={ctaText}
              link={"#"}
              backgroundColor={"#66CAEB"}
              backgroundColorHover={"#66CAEB"}
              color={"#232530"}
            />
          </CtaButtonContainer>
        </HeaderContainerInner>
        <HomeSplashBackgroundContainer>
          <SplashBackgroundInnerContainer>
            <HomeSplashBackground
              initial="hidden"
              animate={isInView ? "visible" : "hidden"}
              variants={BackgroundImageAnim}
              imageUrl={backgroundImage.filename}
              mobileImageUrl={backgroundImage.filename}
            />
          </SplashBackgroundInnerContainer>
        </HomeSplashBackgroundContainer>
      </HeaderContainer>

      <VideoContainerOuter>
        <VideoContainer
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          variants={VideoHomeAnim}
          image={videoThumbnailImg.filename}
          onHoverStart={() => setPlayHovered(true)}
          onHoverEnd={() => setPlayHovered(false)}
          onClick={() => setVideoOpen(true)}
        >
          <PlayIconContainer
            animate={playHovered ? "visible" : "hidden"}
            variants={PlayAnim}
            initial="hidden"
          >
            <PlayIconContainerInner>
              <PlayIcon height="20px" fill={"#fff"} stroke={"#fff"} />
            </PlayIconContainerInner>
          </PlayIconContainer>
        </VideoContainer>
      </VideoContainerOuter>
    </HeaderContainerOuter>
  );
}

export default HeaderWithVideo;
