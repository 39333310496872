import React from "react";

import InlineImage from "../../inlineImage";
import { getFluidGatsbyImage } from "../../../utils/gatsby-storyblok-image-fixed";

function StoryblokInlineImageAdapter({ image, photoCredit, imageCaption }) {
  let fluidImage = getFluidGatsbyImage(image, {
    maxWidth: 1920,
  });

  return (
    <InlineImage
      image={fluidImage}
      photoCredit={photoCredit}
      imageCaption={imageCaption}
    />
  );
}

export default StoryblokInlineImageAdapter;
