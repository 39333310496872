import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import useInView from "react-cool-inview";

import LazyImage from "./lazy-image";
import RichtextParser from "../utils/richtext-parser";
import ConvertHtml from "../utils/convert-html";
import { getFluidGatsbyImage } from "../utils/gatsby-storyblok-image-fixed/";

const TimelineItemContainer = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  min-height: 700px;
  padding: 0px 20px;
`;

const Divider = styled(motion.div)`
  width: 2px;
  background-color: #fde115;
  @media (max-width: 900px) {
    display: none !important;
  }
`;

const YearContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(-90deg);
  width: 20px;
  margin-left: 100px;
  margin-right: 0px;
  @media (max-width: 900px) {
    display: none !important;
  }
`;

const Year = styled(motion.div)`
  height: 20px;
  letter-spacing: 45px;
  line-height: 0;
  font-size: 175px;
  font-weight: 600;
  opacity: 0.03;
  margin-right: -30px;
  @media (max-width: 1200px) {
    font-size: 60px;
  }
  @media (max-width: 900px) {
    display: none;
  }
`;

const ItemContentContainer = styled.div`
  width: 100%;
  margin-left: 50px;
`;

const TimelineContent = styled.div``;

const Title = styled(motion.div)`
  font-size: 42px;
  line-height: 46px;
  margin-bottom: 15px;
  @media (max-width: 900px) {
    font-size: 31px;
    line-height: 34px;
  }
`;

const Text = styled(motion.div)`
  font-weight: 500;
  font-size: 17px;
  line-height: 28px;
  color: ${(props) => props.color};
  margin-top: 30px;
  margin-bottom: 30px;
  max-width: 70%;
  z-index: 2;

  @media (max-width: 1100px) {
    max-width: 90%;
  }
  @media (max-width: 800px) {
    max-width: 100%;
  }
`;

const StyledImage = styled(LazyImage)`
  width: 535px;
  margin-bottom: 25px;
  background-color: #3e4658;
  @media (max-width: 900px) {
    width: 100%;
  }
`;

const ImageContainer = styled(motion.div)``;

const ContainerAnim = {
  hidden: {
    opacity: 0,
    transition: {
      duration: 0,
      delay: 0,
    },
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 0,
      duration: 0,
    },
  },
};

const LineAnim = {
  hidden: {
    height: 0,
    transition: {
      duration: 0,
      delay: 0,
    },
  },
  visible: {
    height: "auto",
    transition: {
      delay: 0.1,
      duration: 0.2,
      ease: "easeIn",
    },
  },
};

const ImageAnim = {
  hidden: {
    y: 20,
    opacity: 0,
    transition: {
      duration: 0,
      delay: 0,
    },
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 0.4,
    },
  },
};

const TitleAnim = {
  hidden: {
    y: 20,
    opacity: 0,
    transition: {
      duration: 0,
      delay: 0,
    },
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 0.5,
    },
  },
};

const TextAnim = {
  hidden: {
    y: 20,
    opacity: 0,
    transition: {
      duration: 0,
      delay: 0,
    },
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 0.6,
    },
  },
};

const YearAnim = {
  hidden: {
    y: 20,
    opacity: 0,
    transition: {
      duration: 0,
      delay: 0,
    },
  },
  visible: {
    y: 0,
    opacity: 0.04,
    transition: {
      delay: 0.7,
    },
  },
};

function TimelineItem({ content }) {
  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    setIsInView(false);
    setTimeout(() => {
      setIsInView(true);
    }, 100);
  }, [content]);

  let image = getFluidGatsbyImage(content.image, {
    maxWidth: 800,
  });

  return (
    <TimelineItemContainer
      initial="hidden"
      animate={isInView ? "visible" : "hidden"}
      variants={ContainerAnim}
    >
      <YearContainer>
        <Year
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          variants={YearAnim}
        >
          {content.year}
        </Year>
      </YearContainer>

      <Divider
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        variants={LineAnim}
      />

      <ItemContentContainer>
        <ImageContainer
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          variants={ImageAnim}
        >
          <StyledImage fluid={image} />
        </ImageContainer>
        <TimelineContent>
          <Title
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            variants={TitleAnim}
          >
            {content.title}
          </Title>
          <Text
            dangerouslySetInnerHTML={ConvertHtml(RichtextParser(content.text))}
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            variants={TextAnim}
          />
        </TimelineContent>
      </ItemContentContainer>
    </TimelineItemContainer>
  );
}

export default TimelineItem;
