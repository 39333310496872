import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { render } from "storyblok-rich-text-react-renderer";
import { motion } from "framer-motion";

const Title = styled(motion.h4)`
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -1px;
  color: #000;
  font-family: "Poppins";
  z-index: 2;
  margin-bottom: 0.6rem;
  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0px;
  }
`;

const Paragraph = styled(motion.div)`
  font-weight: 500;
  font-size: 17px;
  line-height: 28px;
  color: ${(props) => props.color};
  margin-top: 10px;
  margin-bottom: 30px;
  z-index: 2;
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 21px;
  }
  h6 {
    line-height: 17px;
    margin-bottom: 0px;
  }
  a {
    color: unset;
    text-decoration: none;
  }
`;

const ObjectivesContainer = styled(motion.div)``;

const ObjectiveItem = styled(motion.div)``;

const ObjectivesAnim = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.1,
    },
  },
};

const ObjectiveItemAnim = {
  hidden: { opacity: 0, y: 40 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.4,
    },
  },
};

function ObjectivesCategory({ data }) {
  const [doAnim, setDoAnim] = useState(false);
  useEffect(() => {
    setDoAnim(false);
    setTimeout(() => {
      setDoAnim(true);
    }, 25);
  }, [data]);
  return (
    <ObjectivesContainer
      initial="hidden"
      animate={doAnim ? "visible" : "hidden"}
      variants={ObjectivesAnim}
    >
      {data.map((objective, i) => (
        <ObjectiveItem key={i} variants={ObjectiveItemAnim}>
          <Title>{objective.title}</Title>
          <Paragraph>{render(objective.paragraph)}</Paragraph>
        </ObjectiveItem>
      ))}
    </ObjectivesContainer>
  );
}

export default ObjectivesCategory;
