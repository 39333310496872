import React, { useState } from "react";
import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import useInView from "react-cool-inview";

import CtaButton from "./ctaButton";
import parseStoryblokLink from "../utils/link-parser";

const JoinPromptContainer = styled.div`
  height: 400px;
  width: 100%;
  padding: 0px 20px;
  background-color: #fde115;
  color: #2c3445;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${(props) =>
    props.marginMode &&
    css`
      width: calc(100% - 40px);
      margin-left: 20px;
    `}
  @media(max-width: 670px) {
    ${(props) =>
      props.marginMode &&
      css`
        width: calc(100% - 15px);
        margin-left: 7.5px;
      `}
  }
`;

const Title = styled(motion.div)`
  font-weight: 700;
  font-size: 42px;
  line-height: 46px;
  letter-spacing: -1px;
  font-family: "Poppins";
  text-align: center;
  @media (max-width: 786px) {
    font-size: 32px;
  }
`;

const Subtitle = styled(motion.div)`
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  color: ${(props) => props.color};
  margin-top: 30px;
  margin-bottom: 30px;
  max-width: 600px;
  text-align: center;
  @media (max-width: 786px) {
    font-size: 18px;
  }
`;

const CtaContainer = styled(motion.div)``;

const TitleAnim = {
  hidden: {
    opacity: 0,
    y: 20,
    transition: {
      delay: 0,
    },
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.2,
    },
  },
};

const SubtitleAnim = {
  hidden: {
    opacity: 0,
    y: 20,
    transition: {
      delay: 0,
    },
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.3,
    },
  },
};

const CtaAnim = {
  hidden: {
    opacity: 0,
    y: 20,
    transition: {
      delay: 0,
    },
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.4,
    },
  },
};

function JoinPrompt({ title, subtitle, ctaLink, ctaText, marginMode }) {
  const [isInView, setIsInView] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0,
    onEnter: ({ scrollDirection, entry, observe, unobserve }) => {
      setIsInView(true);
    },
  });
  console.log(ctaLink);
  return (
    <JoinPromptContainer ref={ref} marginMode={marginMode}>
      <Title
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        variants={TitleAnim}
      >
        {title}
      </Title>
      <Subtitle
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        variants={SubtitleAnim}
      >
        {subtitle}
      </Subtitle>
      <CtaContainer
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        variants={CtaAnim}
      >
        <CtaButton
          text={ctaText}
          link={ctaLink ? parseStoryblokLink(ctaLink) : "/membership"}
          backgroundColor={"#2c3445"}
          backgroundColorHover={"#153BF4"}
          color={"#fde115"}
        />
      </CtaContainer>
    </JoinPromptContainer>
  );
}

export default JoinPrompt;
