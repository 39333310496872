import React from "react";

import AnimatedHeader from "../../animatedHeader";
import { getFluidGatsbyImage } from "../../../utils/gatsby-storyblok-image-fixed";

function StoryblokAnimatedHeaderAdapter({ image, ...rest }) {
  let fluidImage = getFluidGatsbyImage(image, {
    maxWidth: 1920,
  });
  return <AnimatedHeader image={fluidImage} {...rest} />;
}

export default StoryblokAnimatedHeaderAdapter;
