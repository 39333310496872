import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Parallax } from "react-scroll-parallax";
import useInView from "react-cool-inview";

import CtaButton from "./ctaButton";
import LazyImage from "./lazy-image";
import convertHtml from "../utils/convert-html";
import richtextParser from "../utils/richtext-parser";
import linkParser from "../utils/link-parser";

const BannerContainer = styled.div`
  min-height: 890px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  background: linear-gradient(161.39deg, #1c2945 13.27%, #0a1328 89.13%),
    #fbf200;
  overflow: hidden;
  @media (max-width: 1020px) {
    min-height: unset;
  }
`;

const BannerContentContainer = styled.div`
  padding-left: 150px;
  padding-right: 150px;
  width: 100%;
  max-width: 1400px;
  position: relative;
  @media (max-width: 1230px) {
    padding-left: 50px;
    padding-right: 50px;
  }
  @media (max-width: 1020px) {
    display: none;
  }
`;

const BannerContentMobileContainer = styled.div`
  display: none;
  @media (max-width: 1020px) {
    display: block;
    width: 100%;
    margin: 70px 25px 0px 25px;
  }
  @media (max-width: 600px) {
    margin: 40px 25px 0px 25px;
  }
`;

const TitleContainer = styled.div`
  width: 100%;
  margin-top: 70px;
  z-index: 2;
  position: absolute;
  top: 0;
  @media (max-width: 1020px) {
    position: unset;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 0px;
  }
`;

const Subtitle = styled(motion.div)`
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  display: flex;
  align-items: center;
  color: #ffffff;
  z-index: 2;
  @media (max-width: 1170px) {
    margin-left: 5px;
  }
  @media (max-width: 1020px) {
    font-size: 23px;
    line-height: 30px;
  }
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 24px;
    margin-left: 0px;
    margin-bottom: 5px;
  }
  @media (max-width: 400px) {
    font-size: 15px;
    line-height: 22px;
    max-width: 200px;
  }
`;

const Title = styled(motion.div)`
  font-weight: bold;
  font-size: 144px;
  line-height: 130px;
  display: flex;
  align-items: center;
  color: #fde115;
  text-transform: uppercase;
  z-index: 2;
  letter-spacing: 12px;
  @media (max-width: 1170px) {
    font-size: 120px;
    line-height: 90px;
  }
  @media (max-width: 1020px) {
    font-size: 80px;
    line-height: 80px;
  }
  @media (max-width: 767px) {
    font-size: 50px;
    line-height: 50px;
    letter-spacing: 1px;
  }
  @media (max-width: 370px) {
    font-size: 30px;
    line-height: 30px;
  }
`;

const ImageContainer = styled(motion.div)`
  width: 505px;
  height: 100%;
  display: flex;
  align-items: flex-end;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 20%;
  z-index: 3;
  @media (max-width: 1150px) {
    margin-right: 22%;
  }
  @media (max-width: 1020px) {
    margin-right: 0px;
    position: relative;
    width: 100%;
  }
`;

const PlayerImage = styled(LazyImage)`
  width: 505px;
  z-index: 3;
  margin-bottom: -20px;
  @media (max-width: 1020px) {
    width: 100%;
  }
  @media (max-width: 767px) {
    right: -20px;
  }
`;

const StatBarOuterContainer = styled(motion.div)`
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  z-index: 1;
  padding-top: 20%;
  margin-right: 50px;
  width: 395px;
  height: 100%;
  @media (max-width: 1250px) {
    margin-right: 25px;
  }
`;

const StatsBarContainer = styled(motion.div)`
  width: 395px;
  height: 890px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  padding-top: 280px;

  background: linear-gradient(
    360deg,
    #2c3445 21.35%,
    rgba(25, 27, 60, 0) 74.48%
  );
  @media (max-width: 1170px) {
    margin-right: 10px;
  }
  @media (max-width: 1100px) {
    padding-top: 250px;
  }
  @media (max-width: 1020px) {
    background: unset;
    position: unset;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 50px;
    margin: 0px;
    height: unset;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    margin-left: 10px;
    padding-top: 0px;
  }
`;

const StatContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  @media (max-width: 1020px) {
    justify-content: center;
  }
  @media (max-width: 600px) {
    margin-bottom: 30px;
  }
`;

const StatNum = styled(motion.div)`
  font-weight: bold;
  font-size: 82px;
  line-height: 78px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  color: #fde115;
  @media (max-width: 1020px) {
    font-size: 60px;
  }
  @media (max-width: 600px) {
    font-size: 40px;
    line-height: 45px;
  }
`;

const StatSub = styled(motion.div)`
  font-size: 24px;
  line-height: 125%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

const ParagraphContainer = styled(motion.div)`
  width: 530px;
  color: #ffffff;
  margin-top: 270px;
  @media (max-width: 1360px) {
    width: 470px;
  }
  @media (max-width: 1300px) {
    width: 440px;
  }
  @media (max-width: 1230px) {
    width: 370px;
  }
  @media (max-width: 1170px) {
    width: 400px;
  }
  @media (max-width: 1100px) {
    width: 340px;
  }
  @media (max-width: 1020px) {
    width: 100%;
    margin-top: 25px;
  }
`;

const Paragraph = styled.div``;

const CallToActionContainer = styled(motion.div)`
  width: 170px;
  margin-top: 25px;
`;

const LowerContentContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
`;

const LeftContent = styled.div`
  width: 75%;
  @media (max-width: 767px) {
    width: 20%;
    margin-left: 25px;
    left: 20px;
  }
`;

const RightContent = styled.div`
  width: 35%;
  display: flex;
  height: 100%;
  align-items: flex-end;
  min-height: 100%;
  @media (max-width: 767px) {
    width: 80%;
    margin-right: 0px;
  }
`;

const Tablet = styled.div`
  @media (max-width: 767px) {
    display: none;
  }
`;

const Mobile = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
`;

const StyledParallax = styled(Parallax)`
  width: 100%;
  height: 100%;
`;

const SubTitleAnim = {
  hidden: { opacity: 0, y: 10 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.4,
      delay: 0,
    },
  },
};

const TitleAnim = {
  hidden: { opacity: 0, y: 10 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.4,
      delay: 0.05,
    },
  },
};

const ParaAnim = {
  hidden: { opacity: 0, y: 10 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.2,
      delay: 0.2,
    },
  },
};

const CtaAnim = {
  hidden: { opacity: 0, y: 10 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.2,
      delay: 0.25,
    },
  },
};

const StatBarAnim = {
  hidden: { height: 0 },
  visible: {
    height: "100%",

    transition: {
      duration: 0.4,
      delay: 0.4,
      ease: "easeIn",
    },
  },
};

const StatNumAnimOne = {
  hidden: { opacity: 0, y: 10 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.2,
      delay: 0.5,
      ease: "easeIn",
    },
  },
};

const StatTextAnimOne = {
  hidden: { opacity: 0, y: 10 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.2,
      delay: 0.55,
    },
  },
};

const StatNumAnimTwo = {
  hidden: { opacity: 0, y: 10 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.2,
      delay: 0.6,
      ease: "easeIn",
    },
  },
};

const StatTextAnimTwo = {
  hidden: { opacity: 0, y: 10 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.2,
      delay: 0.65,
    },
  },
};

const StatNumAnimThree = {
  hidden: { opacity: 0, y: 10 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.2,
      delay: 0.7,
      ease: "easeIn",
    },
  },
};

const StatTextAnimThree = {
  hidden: { opacity: 0, y: 10 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.2,
      delay: 0.75,
    },
  },
};

const ImageAnim = {
  hidden: { opacity: 0, x: -40 },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.7,
      delay: 0.75,
    },
  },
};

function MotmHomeBanner({
  image,
  subtitle,
  title,
  dataOneNumber,
  dataOneSubtitle,
  dataTwoNumber,
  dataTwoSubtitle,
  dataThreeNumber,
  dataThreeSubtitle,
  paragraph,
  ctaText,
  ctaLink,
}) {
  const [isInView, setIsInView] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0.5,
    onEnter: ({ scrollDirection, entry, observe, unobserve }) => {
      setIsInView(true);
    },
  });
  return (
    <BannerContainer ref={ref}>
      <BannerContentContainer>
        <TitleContainer>
          <Subtitle
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            variants={SubTitleAnim}
          >
            {subtitle}
          </Subtitle>
          <Title
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            variants={TitleAnim}
          >
            {title}
          </Title>
        </TitleContainer>
        <ParagraphContainer
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          variants={ParaAnim}
        >
          <Paragraph
            dangerouslySetInnerHTML={convertHtml(richtextParser(paragraph))}
          />
        </ParagraphContainer>
        {/*
        <CallToActionContainer
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          variants={CtaAnim}
        >
          <CtaButton text={ctaText} link={linkParser(ctaLink)} />
        </CallToActionContainer>
        */}
        <StatBarOuterContainer
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          variants={StatBarAnim}
        >
          <StatsBarContainer>
            <StatContainer>
              <StatNum
                initial="hidden"
                animate={isInView ? "visible" : "hidden"}
                variants={StatNumAnimOne}
              >
                {dataOneNumber}
              </StatNum>
              <StatSub
                initial="hidden"
                animate={isInView ? "visible" : "hidden"}
                variants={StatTextAnimOne}
              >
                {dataOneSubtitle}
              </StatSub>
            </StatContainer>
            <StatContainer>
              <StatNum
                initial="hidden"
                animate={isInView ? "visible" : "hidden"}
                variants={StatNumAnimTwo}
              >
                {dataTwoNumber}
              </StatNum>
              <StatSub
                initial="hidden"
                animate={isInView ? "visible" : "hidden"}
                variants={StatTextAnimTwo}
              >
                {dataTwoSubtitle}
              </StatSub>
            </StatContainer>
            <StatContainer>
              <StatNum
                initial="hidden"
                animate={isInView ? "visible" : "hidden"}
                variants={StatNumAnimThree}
              >
                {dataThreeNumber}
              </StatNum>
              <StatSub
                initial="hidden"
                animate={isInView ? "visible" : "hidden"}
                variants={StatTextAnimThree}
              >
                {dataThreeSubtitle}
              </StatSub>
            </StatContainer>
          </StatsBarContainer>
        </StatBarOuterContainer>
        <ImageContainer
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          variants={ImageAnim}
        >
          <PlayerImage fluid={image} />
        </ImageContainer>
      </BannerContentContainer>
      <BannerContentMobileContainer>
        <TitleContainer>
          <Subtitle>{subtitle}</Subtitle>
          <Title>{title}</Title>
        </TitleContainer>
        <Mobile>
          <ParagraphContainer>
            <Paragraph
              dangerouslySetInnerHTML={convertHtml(richtextParser(paragraph))}
            />
          </ParagraphContainer>
        </Mobile>
        <LowerContentContainer>
          <LeftContent>
            <Tablet>
              <ParagraphContainer>
                <Paragraph
                  dangerouslySetInnerHTML={convertHtml(
                    richtextParser(paragraph)
                  )}
                />
              </ParagraphContainer>
            </Tablet>
            <StatsBarContainer>
              <StatContainer>
                <StatNum>{dataOneNumber}</StatNum>
                <StatSub>{dataOneSubtitle}</StatSub>
              </StatContainer>
              <StatContainer>
                <StatNum>{dataTwoNumber}</StatNum>
                <StatSub>{dataTwoSubtitle}</StatSub>
              </StatContainer>
              <StatContainer>
                <StatNum>{dataThreeNumber}</StatNum>
                <StatSub>{dataThreeSubtitle}</StatSub>
              </StatContainer>
            </StatsBarContainer>
          </LeftContent>
          <RightContent>
            <ImageContainer>
              <PlayerImage fluid={image} />
            </ImageContainer>
          </RightContent>
        </LowerContentContainer>
      </BannerContentMobileContainer>
    </BannerContainer>
  );
}

export default MotmHomeBanner;
