import React from "react";
import { getFluidGatsbyImage } from "../../../utils/gatsby-storyblok-image-fixed";

import HeaderWithVideo from "../../headerWithVideo";

function storyblokHeaderWithVideo({ videoThumbnail, ...rest }) {
  let videoThumbnailImg = getFluidGatsbyImage(videoThumbnail, {
    maxWidth: 900,
  });
  return <HeaderWithVideo videoThumbnailImg={videoThumbnail} {...rest} />;
}

export default storyblokHeaderWithVideo;
