import React from "react";
import BoardMembers from "../../boardMembers";

function StoryblokBoardMembersAdapter({ title, paragraph }) {
  if (typeof window === `undefined`) {
    return <></>;
  }
  return <BoardMembers title={title} paragraph={paragraph} />;
}

export default StoryblokBoardMembersAdapter;
