import React from "react";
import RichtextParser from "../../../utils/richtext-parser";
import convertHtml from "../../../utils/convert-html";
import { getFluidGatsbyImage } from "../../../utils/gatsby-storyblok-image-fixed";

import TitleAndText from "../../titleAndText";
import { textColor } from "styled-system";

function StoryblokTitleAndText({
  paragraph,
  backgroundColor,
  subtitleColor,
  titleColor,
  textColor,
  leftImage,
  hideImageOnMobile,
  fullWidth,
  ...rest
}) {
  let fluidImage = getFluidGatsbyImage(leftImage, {
    maxWidth: 1000,
  });
  return (
    <TitleAndText
      paragraph={convertHtml(RichtextParser(paragraph))}
      backgroundColor={backgroundColor.color}
      subtitleColor={subtitleColor.color}
      titleColor={titleColor.color}
      textColor={textColor.color}
      image={fluidImage}
      hideImageOnMobile={hideImageOnMobile}
      fullWidth={fullWidth}
      {...rest}
    />
  );
}

export default StoryblokTitleAndText;
