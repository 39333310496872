import React from "react";

import HomeHeader from "../../homeHeader";
import { getFluidGatsbyImage } from "../../../utils/gatsby-storyblok-image-fixed";

function StoryblokHomeHeaderAdapter({ image, underlineImage, mobileImage }) {
  let fluidImage = getFluidGatsbyImage(image, {
    maxWidth: 1920,
  });

  let fluidUnderlineImage = getFluidGatsbyImage(underlineImage, {
    maxWidth: 1900,
  });

  return (
    <HomeHeader
      image={fluidImage}
      imageUrl={image.filename}
      underlineImg={fluidUnderlineImage}
      underlineImageUrl={underlineImage.filename}
      mobileImageUrl={mobileImage.filename}
    />
  );
}

export default StoryblokHomeHeaderAdapter;
