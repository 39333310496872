import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import useInView from "react-cool-inview";
import { useStaticQuery, graphql } from "gatsby";

import FilterDownIcon from "../assets/images/icons/filterDownIcon.svg";

const BlogFilteringContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const ActiveFilterText = styled.div`
  font-size: 24px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #000000;
  margin-right: 10px;
  font-weight: bold;
`;

const FilterDropdownIconContainer = styled.div`
  margin-top: -2px;
`;

const Dropdown = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: #eee;
  height: 100px;
  width: 100px;
  margin-top: 50px;
  z-index: 5;
`;

const CategoryItem = styled.div``;

const FilterAnim = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 1.4,
    },
  },
};

function BlogFiltering({ setActiveCategory }) {
  const [categories, setCategories] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const data = useStaticQuery(graphql`
    query ArticleCategories {
      categories: allStoryblokEntry(
        filter: { field_component: { eq: "newsCategory" } }
      ) {
        edges {
          node {
            name
            content
          }
        }
      }
    }
  `);

  let categoriesArr = [];

  data.categories.edges.forEach((category) => {
    let tempData = JSON.parse(category.node.content);
    let categoryItem = {
      title: tempData.title,
      prettyTitle: tempData.prettyTitle,
    };
    categoriesArr.push(categoryItem);
  });

  useEffect(() => {
    setTimeout(() => {
      setCategories(categoriesArr);
    }, 500);
  }, []);

  return (
    <BlogFilteringContainer
      initial="hidden"
      animate={"visible"}
      variants={FilterAnim}
      onClick={() => setShowDropdown(!showDropdown)}
    >
      <ActiveFilterText>Latest Articles</ActiveFilterText>
    </BlogFilteringContainer>
  );
}

export default BlogFiltering;
