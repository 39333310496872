import React, { useRef, useState } from "react";
import styled, { css } from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import windowSize from "react-window-size";
import ArrowRight from "../assets/images/icons/arrow-right.svg";
import { render } from "storyblok-rich-text-react-renderer";

import "swiper/swiper.scss";

import BoardMemberCard from "./boardMemberCard";

const BoardMembersOuterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BoardMembersContentContainer = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fde115;
`;

const BoardMembersContainer = styled.div`
  max-width: 1100px;
  width: 100%;
  display: flex;
  padding: 60px 0;
  position: relative;
  flex-direction: column;
  overflow: hidden;
`;

const Title = styled.h4`
  font-weight: 700;
  font-size: 62px;
  line-height: 62px;
  letter-spacing: -1px;
  font-family: "Poppins";
  margin-bottom: 5px;
  @media (max-width: 768px) {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 30px;
    line-height: 37px;
    margin-bottom: 15px;
  }
`;

const Paragraph = styled.div`
  font-weight: 500;
  font-size: 17px;
  line-height: 28px;
  max-width: 900px;
  margin-bottom: 35px;
  a {
    color: #2c3445;
    text-decoration: none;
    font-weight: 600;
  }
  @media (max-width: 768px) {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 15px;
    margin-bottom: 75px;
  }
`;

const SwiperOuterContainer = styled.div`
  width: 100%;
  position: relative;
  @media (max-width: 768px) {
    padding: 0px 15px;
    overflow: visible;
  }
`;

const NavigationContainer = styled.div`
  width: fit-content;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0px 20px;
  ${(props) =>
    props.prev &&
    css`
      left: 0;
      right: unset;
      }
    `}
  @media(max-width: 768px) {
    padding: 0;
    width: 60px;
    height: 60px;
  }
`;

const NavigationButtonItem = styled.div`
  background-color: #3793eb;
  height: 60px;
  width: 60px;
  min-height: 60px;
  min-width: 60px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  will-change: transform;
  transition: 400ms;
  opacity: 0.4;

  :hover {
    transform: scale(1.1);
    transition: 400ms;
    opacity: 1;
  }
  ${(props) =>
    props.prev &&
    css`
      transform: rotate(180deg);
      :hover {
        transform: scale(1.1) rotate(180deg);
        transition: 400ms;
      }
    `}
  @media (max-width: 768px) {
    opacity: 1;
  }
`;

const NavContainer = styled.div`
  @media (max-width: 768px) {
    position: absolute;
    top: -80px;
    width: calc(100vw - 30px);
    padding: 0px 15px;
  }
`;

const NavigationButton = React.forwardRef((props, ref) => {
  return (
    <NavigationContainer {...props}>
      <NavigationButtonItem {...props} ref={ref}>
        <ArrowRight fill={"#2c3445"} stroke={"#2c3445"} />
      </NavigationButtonItem>
    </NavigationContainer>
  );
});

function BoardMembers({ title, paragraph, windowWidth }) {
  const prevElRef = useRef(null);
  const nextElRef = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const data = useStaticQuery(graphql`
    query BoardMembers {
      boardMembers: allStoryblokEntry(
        filter: { field_component: { eq: "boardMember" } }
      ) {
        edges {
          node {
            position
            content
          }
        }
      }
    }
  `);
  let boardMembers = data.boardMembers.edges;

  SwiperCore.use([Autoplay, Navigation]);
  return (
    <BoardMembersOuterContainer>
      <BoardMembersContentContainer>
        <BoardMembersContainer>
          <Title>{title}</Title>
          <Paragraph>{render(paragraph)}</Paragraph>
          <SwiperOuterContainer>
            <Swiper
              spaceBetween={10}
              slidesPerView={"auto"}
              autoplay={{ delay: 4000 }}
              onSlideChange={(swiper) => setActiveSlide(swiper.activeIndex)}
              loop={true}
              navigation={{
                prevEl: prevElRef.current,
                nextEl: nextElRef.current,
              }}
            >
              {boardMembers.map((data, i) => {
                let boardMember = JSON.parse(data.node.content);
                if (typeof window !== `undefined`) {
                  return (
                    <SwiperSlide
                      style={{ width: windowWidth > 768 ? "310px" : "250px" }}
                      key={i}
                    >
                      <BoardMemberCard data={boardMember} />
                    </SwiperSlide>
                  );
                } else {
                  return null;
                }
              })}
            </Swiper>
            <NavContainer>
              <NavigationButton
                prev
                ref={prevElRef}
                windowWidth={windowWidth}
              />
              <NavigationButton
                next
                ref={nextElRef}
                windowWidth={windowWidth}
              />
            </NavContainer>
          </SwiperOuterContainer>
        </BoardMembersContainer>
      </BoardMembersContentContainer>
    </BoardMembersOuterContainer>
  );
}

export default windowSize(BoardMembers);
