import React from "react";
import ScrollableAnchor, { configureAnchors } from "react-scrollable-anchor";

function Anchor({ id }) {
  configureAnchors({ offset: -100, scrollDuration: 200 });
  return (
    <ScrollableAnchor id={id}>
      <div></div>
    </ScrollableAnchor>
  );
}

export default Anchor;
