import React from "react";
import styled from "styled-components";

import CtaButton from "./ctaButton";

const LargeCtaBannerContainer = styled.div`
  height: 375px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.backgroundColor};
  flex-direction: column;
  @media (max-width: 768px) {
    height: unset;
    padding: 50px 25px;
  }
`;

const TitleText = styled.div`
  color: ${(props) => props.titleColor};
  font-weight: bold;
  font-size: 48px;
  line-height: 58px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.03em;
  width: 640px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 38px;
    line-height: 46px;
    width: unset;
  }
`;

function LargeCtaBanner({
  title,
  titleColor,
  ctaText,
  link,
  ctaButtonColor,
  ctaButtonTextColor,
  backgroundColor,
  ctaButtonHoverColor,
}) {
  return (
    <LargeCtaBannerContainer backgroundColor={backgroundColor.color}>
      <TitleText color={titleColor.color}>{title}</TitleText>
      <CtaButton
        text={ctaText}
        link={link}
        backgroundColor={ctaButtonColor.color}
        backgroundColorHover={ctaButtonHoverColor.color}
        color={ctaButtonTextColor.color}
      />
    </LargeCtaBannerContainer>
  );
}

export default LargeCtaBanner;
