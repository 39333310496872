import React from "react";

import ComponentNotFound from "../not-found";
import useStoryblokComponents from "./use-storyblok-components";

function StoryblokComponent({ component, ...rest }) {
  const Component = useStoryblokComponents(component);
  return Component ? (
    <Component {...rest} />
  ) : (
    <ComponentNotFound componentName={component} />
  );
}

export default StoryblokComponent;
