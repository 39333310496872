import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { motion, AnimateSharedLayout, AnimatePresence } from "framer-motion";
import windowSize from "react-window-size";
import { render } from "storyblok-rich-text-react-renderer";
import ObjectivesCategory from "./objectivesCategory";
import Chevron from "../assets/images/icons/chevronRight.svg";

const ObjectivesContainer = styled.div`
  padding-top: 45px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  @media (max-width: 950px) {
    padding-top: 45px;
  }
`;

const ObjectivesContent = styled.div`
  max-width: calc(100% - 40px);
  width: 100%;
  margin: auto;
  padding: 60px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.backgroundColor};
  @media (max-width: 950px) {
    padding-top: 0px;
  }
  @media (max-width: 768px) {
    padding-bottom: 25px;
  }
`;

const ObjectivesContainerInner = styled.div`
  max-width: 1100px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  @media (max-width: 950px) {
    flex-direction: column;
  }
  @media (max-width: 650px) {
    padding: 0px;
  }
`;

const TextContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled(motion.h4)`
  font-weight: 700;
  font-size: 62px;
  line-height: 62px;
  letter-spacing: -1px;
  color: ${(props) => props.color};
  font-family: "Poppins";
  margin-bottom: 15px;
  z-index: 2;
  @media (max-width: 1080px) {
    font-size: 52px;
  }
  @media (max-width: 768px) {
    font-size: 42px;
    line-height: 46px;
  }
`;

const Paragraph = styled(motion.div)`
  font-weight: 500;
  font-size: 17px;
  line-height: 28px;
  color: ${(props) => props.color};
  margin-top: 30px;
  margin-bottom: 30px;
  max-width: 70%;
  z-index: 2;
  @media (max-width: 1490px) {
    max-width: 600px;
  }
  @media (max-width: 1230px) {
    max-width: 500px;
  }
  @media (max-width: 1080px) {
    max-width: 400px;
  }
  @media (max-width: 950px) {
    max-width: 75%;
  }
  @media (max-width: 768px) {
    max-width: 100%;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

const ObjectivesSection = styled(motion.div)`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-top: 50px;
  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 25px;
  }
`;

const CategorySelector = styled.div`
  max-width: 475px;
  margin-right: 75px;
  @media (max-width: 768px) {
    display: flex;
    overflow-x: auto;
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
  }
`;

const CategoryContent = styled.div`
  max-width: 600px;
  width: 100%;
`;

const ObjectiveCategoryTitle = styled(motion.div)`
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  letter-spacing: -1px;
  color: #000;
  max-width: 475px;
  width: 100%;
  cursor: pointer;
  ${(props) =>
    props.active &&
    css`
      color: #2c3445;
    `}
  @media (max-width: 768px) {
    font-size: 21px;
    line-height: 24px;
  }
`;

const CategorySelectorItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: fit-content;
  position: relative;
  margin-left: 40px;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    white-space: nowrap;
    flex-direction: row-reverse;
    width: fit-content;
    position: relative;
    margin-left: 0px;
    margin-right: 30px;
  }
`;

const ObjectiveCategoryTitleActiveIndicatorContainer = styled(motion.div)`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: -40px;
  width: 30px;
  @media (max-width: 768px) {
    position: absolute;
    left: 0;
    width: 100%;
    top: 37px;
    height: 4px;
    background-color: #2c3445;
  }
`;

const ObjectiveCategoryTitleActiveIndicator = styled(motion.div)`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border-width: 2px;
  border-style: solid;
  border-color: #2c3445;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    display: none;
  }
`;

const CategoryTitleAnim = {
  hidden: {
    x: 0,
  },
  visible: {
    x: 10,
  },
};

function Objectives({ title, subtitle, objectiveContent, windowWidth }) {
  const [activeCategory, setActiveCategory] = useState(0);
  const [showData, setShowData] = useState(true);

  function updateCategory(c) {
    setShowData(false);
    setActiveCategory(c);
    setTimeout(() => {
      setShowData(true);
    }, 50);
  }

  return (
    <ObjectivesContainer>
      <ObjectivesContent>
        <ObjectivesContainerInner>
          <TextContent>
            <Title>{title}</Title>
            <Paragraph>{render(subtitle)}</Paragraph>
          </TextContent>
          <ObjectivesSection>
            <AnimateSharedLayout>
              <CategorySelector>
                {objectiveContent.map((objective, i) => (
                  <CategorySelectorItem key={i}>
                    {i === activeCategory && (
                      <ObjectiveCategoryTitleActiveIndicatorContainer
                        layoutId="highlight"
                        layout="position"
                      >
                        <ObjectiveCategoryTitleActiveIndicator>
                          <Chevron
                            height={"10px"}
                            width={"10px"}
                            fill={"#2C3445"}
                          />
                        </ObjectiveCategoryTitleActiveIndicator>
                      </ObjectiveCategoryTitleActiveIndicatorContainer>
                    )}
                    {typeof window !== `undefined` && (
                      <ObjectiveCategoryTitle
                        active={i === activeCategory ? true : false}
                        onClick={() => updateCategory(i)}
                        initial="hidden"
                        animate={
                          windowWidth > 768
                            ? i === activeCategory
                              ? "visible"
                              : "hidden"
                            : "hidden"
                        }
                        variants={CategoryTitleAnim}
                      >
                        {objective.title}
                      </ObjectiveCategoryTitle>
                    )}
                  </CategorySelectorItem>
                ))}
              </CategorySelector>
            </AnimateSharedLayout>
            <CategoryContent>
              {showData && (
                <ObjectivesCategory
                  data={
                    objectiveContent[activeCategory] &&
                    objectiveContent[activeCategory].items
                  }
                />
              )}
            </CategoryContent>
          </ObjectivesSection>
        </ObjectivesContainerInner>
      </ObjectivesContent>
    </ObjectivesContainer>
  );
}

export default windowSize(Objectives);
