import React, { useState } from "react";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";
import EllipsisText from "react-ellipsis-text";
import { Link } from "gatsby";
import { motion } from "framer-motion";

import LazyImage from "../components/lazy-image";
import { getFluidGatsbyImage } from "../utils/gatsby-storyblok-image-fixed/";

const SkeletonContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const PostThumbnail = styled(LazyImage)`
  transform: scale(1.01);
  text-decoration: none;
  object-fit: cover;
  object-position: center;
  height: 100%;
  z-index: 2;
  background-color: #fdeaeb;
`;

const PostContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const PostTitle = styled(EllipsisText)`
  font-weight: bold;
  font-size: 21px;
  line-height: 25px;
  color: #363131;
  margin-top: 0px;
  margin-bottom: 0px;
  color: #363131;
  text-decoration: none;
  height: 55px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @media (max-width: 776px) {
    height: unset;
  }
`;

const PostExcerpt = styled(EllipsisText)`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: rgba(159, 161, 171, 0.7);
  letter-spacing: 0.3px;
  text-decoration: none;
  margin-top: 5px;
`;

const PostContainer = styled(Link)`
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
`;

const ThumbnailContainer = styled(motion.div)`
  width: 348px;
  height: 223px;
  overflow: hidden;
  margin-bottom: 10px;
  text-decoration: none;
  position: relative;
  @media (max-width: 340px) {
    width: 100%;
    height: 200px;
  }
`;

const PostContainerInner = styled(motion.div)`
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
`;

const ImageAnim = {
  hidden: {
    y: 0,
    transition: {
      duration: 0.2,
    },
  },
  visible: {
    y: -5,
    transition: {},
  },
};

function ArticleLinkBlock({ link, thumbnail, title, snippet }) {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <PostContainer to={"/" + link}>
      <PostContainerInner
        onHoverStart={() => setIsHovered(true)}
        onHoverEnd={() => setIsHovered(false)}
      >
        <ThumbnailContainer
          animate={isHovered ? "visible" : "hidden"}
          variants={ImageAnim}
          initial="hidden"
        >
          <PostThumbnail fluid={thumbnail} />
          <SkeletonContainer>
            <Skeleton height={223} />
          </SkeletonContainer>
        </ThumbnailContainer>
        <PostContent>
          <PostTitle text={title ? title : ""} length={"60"} />
          <PostExcerpt text={snippet ? snippet : ""} length={"160"} />
        </PostContent>
      </PostContainerInner>
    </PostContainer>
  );
}

export default ArticleLinkBlock;
