import React from "react";

import linkParser from "../../../utils/link-parser";
import JoinPrompt from "../../joinPrompt";

function StoryblokJoinPrompt({ link, ...rest }) {
  return <JoinPrompt ctaLink={link} {...rest} />;
}

export default StoryblokJoinPrompt;
