import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import useInView from "react-cool-inview";
import { Parallax } from "react-scroll-parallax";
import EllipsisText from "react-ellipsis-text";
import LazyImage from "./lazy-image";
import Moment from "react-moment";
import { Link } from "gatsby";

import { getFluidGatsbyImage } from "../utils/gatsby-storyblok-image-fixed";

import ReadMoreArrow from "../assets/images/icons/readMoreArrow.svg";

const FeaturedArticleBlockContainer = styled(motion.div)`
  width: 100%;
  background-color: #2c3445;
  min-height: 400px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  padding: 50px 0px;
  height: 100%;
  display: flex;
  align-items: center;
`;

const ImageContainer = styled(motion.div)`
  height: 100%;
  width: 100%;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
`;

const ImageOuterContainer = styled(motion.div)`
  height: 100%;
  width: 100%;
`;

const ImageInnerContainer = styled(motion.div)`
  height: 100%;
  width: 100%;
`;

const StyledLazyImage = styled(LazyImage)`
  height: 100%;
  width: 100%;
  opacity: 0.15;
  -moz-opacity: 15%;
  -webkit-opacity: 15%;
`;

const TextContentContainer = styled.div`
  height: 100%;
  width: 100%;

  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ArticleTitle = styled(motion.h1)`
  font-weight: 600;
  font-size: 32px;
  line-height: 115%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  max-width: 450px;
  width: 90%;
  cursor: pointer;
  justify-content: center;
  @media (max-width: 768px) {
    font-size: 26px;
  }
`;

const ArticleDescription = styled(motion.h2)`
  font-size: 14px;
  line-height: 150%;
  margin-top: -15px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  max-width: 435px;
  width: 80%;
  text-align: center;
  justify-content: center;
`;

const ArticleMetaData = styled.div`
  display: flex;
  justify-content: space-between;
  width: 245px;
  @media (max-width: 330px) {
    flex-direction: column;
  }
`;

const ArticleMetaDataItem = styled(motion.div)`
  font-weight: 400;
  font-size: 12px;
  line-height: 115%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  flex-direction: column;
  @media (max-width: 330px) {
    margin-bottom: 10px;
    margin-top: 10px;
  }
`;

const ReadMoreItem = styled(motion.div)`
  font-size: 14px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: #fde115;
  margin-top: 30px;
  bottom: 0;
  width: 100%;
  justify-content: center;

  cursor: pointer;
`;

const ReadMoreText = styled.div`
  margin-right: 10px;
`;

const ReadMoreArrowContainer = styled(motion.div)`
  margin-top: 2px;
`;

const StyledParallax = styled(Parallax)`
  width: 100%;
  height: 100%;
`;

const FeaturedArticleTitle = styled(motion.div)`
  font-weight: 400;
  font-size: 12px;
  line-height: 115%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;

  letter-spacing: 1.7px;
`;

const FeaturedArticleDivider = styled(motion.div)`
  width: 30px;
  height: 1px;
  background-color: #fde115;
  margin-top: 15px;
  margin-bottom: 15px;
`;

const LinkLayer = styled(Link)`
  width: 100%;
  height: 100%;
  text-decoration: none;
`;

const BlockAnim = {
  hidden: {
    y: 20,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 0.6,
    },
  },
};

const ImageOpacityAnim = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,

    transition: {
      delay: 0.5,
      duration: 0.9,
    },
  },
};

const ImageAnim = {
  hidden: {
    scale: 1.35,
  },
  visible: {
    scale: 1.15,
    transition: {
      delay: 1.1,
      duration: 4,
      ease: "easeOut",
    },
  },
};

const TitleAnim = {
  hidden: {
    y: 20,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 1,
    },
  },
};

const DescAnim = {
  hidden: {
    y: 20,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 1.1,
    },
  },
};

const WrittenAnim = {
  hidden: {
    y: 20,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 1.2,
    },
  },
};

const PublishedAnim = {
  hidden: {
    y: 20,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 1.25,
    },
  },
};

const ButtonAnim = {
  hidden: {
    y: 20,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 1.3,
    },
  },
};

const FeaturedAnim = {
  hidden: {
    y: 20,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 1.4,
    },
  },
};

const FeaturedDivAnim = {
  hidden: {
    y: 20,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 1.45,
    },
  },
};

function FeaturedArticleBlock({ articleData }) {
  const [hovered, setHovered] = useState(false);
  const [buttonHovered, setButtonHovered] = useState(false);
  const [isInView, setIsInView] = useState(false);

  const { ref, inView } = useInView({
    threshold: 0,
    onEnter: ({ scrollDirection, entry, observe, unobserve }) => {
      setIsInView(true);
    },
  });

  let backgroundImage = getFluidGatsbyImage(articleData.content.featuredImage, {
    maxWidth: 1920,
  });

  return (
    <FeaturedArticleBlockContainer
      onHoverStart={() => setHovered(true)}
      onHoverEnd={() => setHovered(false)}
      initial="hidden"
      animate={isInView ? "visible" : "hidden"}
      variants={BlockAnim}
      ref={ref}
    >
      <LinkLayer to={"/" + articleData.full_slug + "/"}>
        <TextContentContainer>
          <FeaturedArticleTitle
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            variants={FeaturedAnim}
          >
            Featured Piece
          </FeaturedArticleTitle>
          <FeaturedArticleDivider
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            variants={FeaturedDivAnim}
          />
          <ArticleTitle
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            variants={TitleAnim}
          >
            <EllipsisText
              text={articleData && articleData.name ? articleData.name : ""}
              length={"60"}
            />
          </ArticleTitle>
          <ArticleDescription
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            variants={DescAnim}
          >
            <EllipsisText
              text={
                articleData && articleData.content.postSnippet
                  ? articleData.content.postSnippet
                  : ""
              }
              length={"140"}
            />
          </ArticleDescription>
          <ArticleMetaData>
            <ArticleMetaDataItem
              initial="hidden"
              animate={isInView ? "visible" : "hidden"}
              variants={WrittenAnim}
            >
              Words by
              <br /> {articleData.content.authorName}
            </ArticleMetaDataItem>
            <ArticleMetaDataItem
              initial="hidden"
              animate={isInView ? "visible" : "hidden"}
              variants={PublishedAnim}
            >
              <div>Published on</div>
              <div>
                <Moment
                  format="Do MMMM, YYYY"
                  date={articleData.content.dateWritten}
                />
              </div>
            </ArticleMetaDataItem>
          </ArticleMetaData>
          <ReadMoreItem
            onHoverStart={() => setButtonHovered(true)}
            onHoverEnd={() => setButtonHovered(false)}
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            variants={ButtonAnim}
          >
            <ReadMoreText>Read More</ReadMoreText>
            <ReadMoreArrowContainer animate={{ x: buttonHovered ? 5 : 0 }}>
              <ReadMoreArrow />
            </ReadMoreArrowContainer>
          </ReadMoreItem>
        </TextContentContainer>
        <ImageContainer
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          variants={ImageOpacityAnim}
        >
          <ImageOuterContainer
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            variants={ImageAnim}
          >
            <ImageInnerContainer animate={{ scale: hovered ? 1.2 : 1.15 }}>
              <StyledLazyImage fluid={backgroundImage} />
            </ImageInnerContainer>
          </ImageOuterContainer>
        </ImageContainer>
      </LinkLayer>
    </FeaturedArticleBlockContainer>
  );
}

export default FeaturedArticleBlock;
