import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import useInView from "react-cool-inview";

import SearchIcon from "../assets/images/icons/searchIcon.svg";

const BlogSearchContainerOuter = styled(motion.div)`
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const BlogSearchContainer = styled(motion.div)`
  width: 205px;
  background: #daf3fb;
  color: #000;
  border: 0px;
  padding: 0px;
  display: flex;
  align-items: center;
  height: 40px;

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 10px;
    display: none;
  }
`;

const BlogSearchContainerMobile = styled.div`
  width: 205px;
  background: #daf3fb;
  color: #000;
  border: 0px;
  padding: 0px;
  display: flex;
  align-items: center;
  height: 40px;
  display: none;
  @media (max-width: 768px) {
    width: 100%;
    margin-top: 10px;
    display: flex;
    height: 50px;
  }
`;

const SearchIconContainer = styled.div`
  margin-right: 10px;
  background-color: #88e1fd;
  width: 40px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BlogSearchInput = styled.input`
  height: 18px;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #000;
  background-color: transparent;
  border-width: 0px;
  z-index: 2;
  outline: none;
  font-family: "Poppins", sans-serif;
  &::placeholder {
    color: #000;
  }
`;

const SearchContainerWidthAnim = {
  hidden: { width: "205px" },
  visible: {
    width: "285px",
    transition: {
      duration: 0.2,
    },
  },
};

const SearchAnim = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 1.5,
    },
  },
};

function BlogSearch({ setMasterSearchInput }) {
  const [focus, setFocus] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    setMasterSearchInput(searchInput);
  }, [searchInput]);

  return (
    <BlogSearchContainerOuter
      initial="hidden"
      animate={"visible"}
      variants={SearchAnim}
    >
      <BlogSearchContainer
        initial="hidden"
        animate={searchActive ? "visible" : focus ? "visible" : "hidden"}
        variants={SearchContainerWidthAnim}
      >
        <SearchIconContainer>
          <SearchIcon stroke={"#232530"} fill={"#232530"} />
        </SearchIconContainer>
        <BlogSearchInput
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          placeholder="Search Articles"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
        ></BlogSearchInput>
      </BlogSearchContainer>
      <BlogSearchContainerMobile>
        <SearchIconContainer>
          <SearchIcon stroke={"#232530"} fill={"#232530"} />
        </SearchIconContainer>
        <BlogSearchInput
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          placeholder="Search Articles"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
        ></BlogSearchInput>
      </BlogSearchContainerMobile>
    </BlogSearchContainerOuter>
  );
}

export default BlogSearch;
