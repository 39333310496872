import React from "react";
import ReactPlayer from "react-player";
import StoryblokLinkParser from "../utils/link-parser";
import styled from "styled-components";

const YouTubeVideoContainer = styled.div`
  max-width: 100%;
`;

function YoutubeVideo({ link }) {
  return (
    <YouTubeVideoContainer>
      <ReactPlayer
        url={StoryblokLinkParser(link)}
        style={{ maxWidth: "100%" }}
      />
    </YouTubeVideoContainer>
  );
}

export default YoutubeVideo;
