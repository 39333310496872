import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import useInView from "react-cool-inview";
import { useStaticQuery, graphql } from "gatsby";

import SimonImage from "../assets/images/simon.webp";
import SimonTwoImage from "../assets/images/simon2.webp";
import ShopArrow from "../assets/images/icons/shopArrow.svg";

const ShopHeaderContainerOuter = styled.div`
  width: 100%;
  height: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Avenir" !important;
  background-color: #f8f8f8;
`;

const ShopHeaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 4px 4px;
  grid-template-areas:
    "Gallery Gallery Gallery Text-Content Text-Content Text-Content Right-Section Right-Section"
    "Gallery Gallery Gallery Text-Content Text-Content Text-Content Right-Section Right-Section";

  @media (max-width: 1400px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "Gallery Text-Content"
      "Gallery Text-Content";
  }
  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column-reverse;
    height: unset;
  }
`;

const GalleryContainer = styled.div`
  grid-area: Gallery;
  background-color: #f9f8f8;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  position: relative;
  @media (max-width: 1000px) {
    height: 800px;
    display: flex;
    justify-content: center;
  }
`;

const TextContainer = styled.div`
  grid-area: Text-Content;
  background-color: #2f3144;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 0px 80px;
  @media (max-width: 1000px) {
    height: 900px;
    padding-top: 225px;
  }
`;

const RightSectionContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  grid-area: Right-Section;
  @media (max-width: 1400px) {
    display: none;
  }
`;

const TopRightImageContainer = styled.div`
  grid-area: Top-Right-Image;
  background-color: #e6e5e5;
  height: 50%;
  width: 100%;
`;

const BottomRightGalleryContainer = styled.div`
  grid-area: Bottom-Right-Gallery;
  background-color: #fff;
  height: 50%;
  width: 100%;
`;

const ProductType = styled.div`
  font-weight: 800;
  font-size: 16px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin-bottom: 25px;
`;

const ProductTitle = styled.div`
  font-weight: 800;
  font-size: 84px;
  line-height: 84px;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin-bottom: 60px;
`;

const ProductDesc = styled.div`
  width: 90%;
  max-width: 525px;
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 32px;
  color: #ffffff;
  margin-bottom: 55px;
`;

const ProductPrice = styled.div`
  font-weight: 800;
  font-size: 63px;
  line-height: 59px;
  color: #f0f000;
  margin-bottom: 75px;
`;

const ProductCta = styled.div``;

const GalleryImage = styled.img`
  width: 90%;
  height: auto;
  margin-bottom: 0;
  @media (max-width: 1000px) {
    height: 90%;
    width: unset;
  }
`;

const GalleryItemName = styled.div`
  font-size: 14px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.19em;
  color: #000000;
  transform: rotate(-90deg);
  text-decoration: uppercase;
  top: 0;
  left: 0;
  position: absolute;
  margin-top: 150px;
  margin-left: -60px;
`;

const GalleryNumberContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-top: 10px;
  margin-right: 10px;
`;

const GalleryNumber = styled.div`
  font-size: 72px;
  line-height: 72px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
`;

const GalleryTotal = styled.div`
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.215em;
  color: #9f9f9f;
  margin-top: -10px;
  margin-right: 10px;
`;

const GalleryNavigationLeft = styled.div`
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
`;

const GalleryNavigationRight = styled.div`
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  transform: rotate(180deg);
  cursor: pointer;
`;

const ArrowContainer = styled.div``;

const TopRightImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const BottomRightGallery = styled.div`
  height: calc(100% - 75px);
`;

const BottomRightNavigation = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
`;

const BottomRightNumbers = styled.div`
  font-weight: 500;
  height: 75px;
  width: 100%;
  font-size: 23px;
  line-height: 31px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #d6d6d6;
  span {
    color: #000000;
  }
`;

const BottomRightArrows = styled.div`
  display: flex;
  align-items: center;
`;

const BottomArrowContainerLeft = styled.div`
  margin-top: 12px;
  margin-right: 10px;
`;

const BottomArrowContainerRight = styled.div`
  transform: rotate(180deg);
`;

function ShopHeader() {
  return (
    <ShopHeaderContainerOuter>
      <ShopHeaderContainer>
        <GalleryContainer>
          <GalleryItemName>Black Mens Lust Hoodie</GalleryItemName>
          <GalleryNumberContainer>
            <GalleryNumber>01</GalleryNumber>
            <GalleryTotal>/02</GalleryTotal>
          </GalleryNumberContainer>
          <GalleryImage src={SimonImage} />
          <GalleryNavigationLeft>
            <ArrowContainer>
              <ShopArrow width={"40px"} height={"40px"} fill={"#000"} />
            </ArrowContainer>
          </GalleryNavigationLeft>
          <GalleryNavigationRight>
            <ArrowContainer>
              <ShopArrow width={"40px"} height={"40px"} fill={"#000"} />
            </ArrowContainer>
          </GalleryNavigationRight>
        </GalleryContainer>
        <TextContainer>
          <ProductType>MEN / HOODIES</ProductType>
          <ProductTitle>Black Mens LUST Hoodie</ProductTitle>
          <ProductDesc>
            Comfortable mens hoodie in black, with the Leeds United Supporters'
            Trust Logo emblazoned on the front left.
          </ProductDesc>
          <ProductPrice>£25.99</ProductPrice>
          <ProductCta>See More</ProductCta>
        </TextContainer>
        <RightSectionContainer>
          <TopRightImageContainer>
            <TopRightImage src={SimonTwoImage} />
          </TopRightImageContainer>
          <BottomRightGalleryContainer>
            <BottomRightGallery></BottomRightGallery>
            <BottomRightNavigation>
              <BottomRightNumbers>
                <span>01</span>/05
              </BottomRightNumbers>
              <BottomRightArrows>
                <BottomArrowContainerLeft>
                  <ShopArrow width={"20px"} height={"20px"} fill={"#000"} />
                </BottomArrowContainerLeft>
                <BottomArrowContainerRight>
                  <ShopArrow width={"20px"} height={"20px"} fill={"#000"} />
                </BottomArrowContainerRight>
              </BottomRightArrows>
            </BottomRightNavigation>
          </BottomRightGalleryContainer>
        </RightSectionContainer>
      </ShopHeaderContainer>
    </ShopHeaderContainerOuter>
  );
}

export default ShopHeader;
