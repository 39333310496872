import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import useInView from "react-cool-inview";
import { useStaticQuery, graphql } from "gatsby";

import ShopHeader from "../shopHeader";
import ShopProduct from "../shopProduct";

import ProductImage from "../../assets/images/product.webp";

const ProductsContainerOuter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
`;

const ProductsContainer = styled.div`
  width: 100%;
  max-width: 1375px;
  margin-left: 32px;
  margin-right: 32px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(500px, 1fr));
  grid-gap: 5px;
  padding: 0px 10px;
  margin-bottom: 20px;
`;

function StoryblokShopPage() {
  return (
    <div>
      <ShopHeader />
      <ProductsContainerOuter>
        <ProductsContainer>
          <ShopProduct
            navigationText={"Womens / Polo Shirts"}
            title={"Ladies' Lust Polo Shirt"}
            price={"£21.99"}
            sizes={"6"}
            image={ProductImage}
          />
          <ShopProduct
            navigationText={"Womens / Polo Shirts"}
            title={"Ladies' Lust Polo Shirt"}
            price={"£21.99"}
            sizes={"6"}
            image={ProductImage}
          />
          <ShopProduct
            navigationText={"Womens / Polo Shirts"}
            title={"Ladies' Lust Polo Shirt"}
            price={"£21.99"}
            sizes={"6"}
            image={ProductImage}
          />
          <ShopProduct
            navigationText={"Womens / Polo Shirts"}
            title={"Ladies' Lust Polo Shirt"}
            price={"£21.99"}
            sizes={"6"}
            image={ProductImage}
          />
          <ShopProduct
            navigationText={"Womens / Polo Shirts"}
            title={"Ladies' Lust Polo Shirt"}
            price={"£21.99"}
            sizes={"6"}
            image={ProductImage}
          />
        </ProductsContainer>
      </ProductsContainerOuter>
    </div>
  );
}

export default StoryblokShopPage;
