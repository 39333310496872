import React from "react";
import convertHtml from "../utils/convert-html";
import ConvertHtml from "../utils/convert-html";
import RichtextParse from "../utils/richtext-parser";

function CodeEmbed({ code }) {
  return <div dangerouslySetInnerHTML={convertHtml(RichtextParse(code))} />;
}

export default CodeEmbed;
