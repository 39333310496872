import React from "react";
import TabbedContent from "../../tabbedContent";
import convertHtml from "../../../utils/convert-html";
import RichtextParser from "../../../utils/richtext-parser";

function StoryblokTabbedContentAdapter({
  backgroundColor,
  titleColor,
  textColor,
  highlightColor,
  tabs,
}) {
  let parsedTabs = [];

  tabs.map((tab, i) => {
    let parsedTab = {
      id: i,
      title: tab.title,
      text: tab.content,
    };
    parsedTabs.push(parsedTab);
  });

  return (
    <TabbedContent
      backgroundColor={backgroundColor.color}
      titleColor={titleColor.color}
      textColor={textColor.color}
      highlightColor={highlightColor.color}
      tabs={parsedTabs}
    />
  );
}

export default StoryblokTabbedContentAdapter;
