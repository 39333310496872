import React from "react";

import TextHeader from "../../textHeader";
import RichtextParser from "../../../utils/richtext-parser";
import convertHtml from "../../../utils/convert-html";

function StoryblokTextHeaderAdapter({ paragraph, ...rest }) {
  return (
    <TextHeader paragraph={convertHtml(RichtextParser(paragraph))} {...rest} />
  );
}

export default StoryblokTextHeaderAdapter;
