import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import useInView from "react-cool-inview";
import { Parallax } from "react-scroll-parallax";

import LazyImage from "../components/lazy-image";

const ImageContainerOuter = styled.div`
  max-width: 960px;
  width: 100%;
  max-height: 100%;
  margin: 50px 0px;
  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
    margin: 20px 0px;
  }
`;

const ImageContainer = styled.div`
  background-color: #e4dfdf;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
`;

const ImageItem = styled(LazyImage)`
  width: 100%;
  height: 100%;
`;

const ImageAnimationLayer = styled(motion.div)`
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 6;
  position: absolute;
  top: 0;
  left: 0;
`;

const ImageCaption = styled(motion.div)`
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #00000096;
  color: #fff;
  padding: 10px;
  max-width: 400px;
  font-size: 12px;
  line-height: 13px;
  letter-spacing: 0.5px;
`;

const StyledParallax = styled(Parallax)`
  width: 100%;
  height: 100%;
`;

const PhotoCredit = styled(motion.div)`
  font-size: 12px;
  color: #000;
  margin-top: 0px;
  text-align: right;
`;

const ImageAnimLayer = {
  hidden: { width: "100%" },
  visible: {
    width: "0%",
    transition: {
      duration: 0.45,
      delay: 0.2,
      ease: "easeIn",
    },
  },
};

const CreditAnim = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.8,
      ease: "easeIn",
    },
  },
};

const CaptionAnim = {
  hidden: { y: 60, opactiy: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 1,
      ease: "easeIn",
      duration: 0.5,
    },
  },
};

function InlineImage({ image, photoCredit, imageCaption }) {
  const [isInView, setIsInView] = useState(false);

  const { ref, inView } = useInView({
    threshold: 0.1,
    onEnter: ({ scrollDirection, entry, observe, unobserve }) => {
      setIsInView(true);
    },
  });

  return (
    <ImageContainerOuter>
      <ImageContainer ref={ref}>
        <ImageAnimationLayer
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          variants={ImageAnimLayer}
        />

        <ImageItem fluid={image} />
        {imageCaption !== "" && (
          <ImageCaption
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            variants={CaptionAnim}
          >
            {imageCaption}
          </ImageCaption>
        )}
      </ImageContainer>
      <PhotoCredit
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        variants={CreditAnim}
      >
        {photoCredit}
      </PhotoCredit>
    </ImageContainerOuter>
  );
}

export default InlineImage;
