import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { render } from "storyblok-rich-text-react-renderer";

const TabContentContainer = styled(motion.div)`
  overflow: hidden;
  font-weight: 500;
  font-size: 15px;
  color: ${(props) => props.color};
  p {
    ::last-of-type {
      margin-bottom: 0px;
      margin-block-end: 0em;
    }
  }
  a {
    color: ${(props) => props.highlight};
    text-decoration: none;
  }
  ul {
    padding-left: 15px;
  }
  h4 {
    color: ${(props) => props.titleColor};
  }
`;

const TextAnim = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.5,
      ease: "easeOut",
    },
  },
};

function TabContent({ data, color, highlight, titleColor }) {
  const [animate, setAnimate] = useState(true);
  return (
    <TabContentContainer
      initial="hidden"
      animate={animate ? "visible" : "hidden"}
      variants={TextAnim}
      color={color ? color : "#000"}
      highlight={highlight ? highlight : "#153BF4"}
      titleColor={titleColor ? titleColor : "#000"}
    >
      {render(data.text)}
    </TabContentContainer>
  );
}

export default TabContent;
