import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import Skeleton from "react-loading-skeleton";
import useDimensions from "react-use-dimensions";
import useInView from "react-cool-inview";
import { Helmet } from "react-helmet";

import LazyImage from "../components/lazy-image";

import ArticleHeader from "../components/articleHeader";
import ArticleHeaderVariant from "../components/articleHeaderVariant";
import StoryblokBloks from "./storyblok/storyblok-bloks";
import RecentArticles from "./recentArticles";

const PostWrapper = styled.div`
  margin-top: 90px;
  font-family: "GT Walsheim", sans-serif;
  margin: 0 auto;
  padding: 0 1.0875rem 1.45rem;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Content = styled(motion.div)`
  font-size: 18px;
  font-weight: 400;
  line-height: 36px;
  color: #2b2a35;
  margin: 0px 35px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  span {
    font-size: 21px;
    line-height: 38px;
    font-weight: 700;
  }
  @media (max-width: 768px) {
    margin: 0px;
  }
`;

const ContentAnim = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.4,
      delay: 0.5,
    },
  },
};

function Article({
  title,
  authorName,
  dateWritten,
  featuredImage,
  body,
  postCategories,
  hideRecentArticles,
  ...rest
}) {
  const [ref, { height }] = useDimensions();
  const [isInView, setIsInView] = useState(true);

  const { pageRef, inView } = useInView({
    threshold: 0.1,
    onEnter: ({ scrollDirection, entry, observe, unobserve }) => {
      setIsInView(true);
    },
  });
  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
            "@context": "https://schema.org",
            "@type": "NewsArticle",
            "headline": "${title}",
            "image": [
              "${featuredImage}",
              
             ],
            "datePublished": "${dateWritten}",
            "dateModified": "${dateWritten}"
    `}
        </script>
      </Helmet>
      <ArticleHeaderVariant
        title={title}
        author={authorName}
        dateWritten={dateWritten}
        featuredImage={featuredImage}
        postCategories={postCategories}
      />

      <PostWrapper ref={pageRef}>
        <Content
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          variants={ContentAnim}
        >
          {body ? (
            <StoryblokBloks body={body} />
          ) : (
            <div>Add some content to Body to get started.</div>
          )}
        </Content>
      </PostWrapper>
      {!hideRecentArticles && <RecentArticles />}
    </>
  );
}

export default Article;
