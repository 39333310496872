import React from "react";
import styled from "styled-components";
import RichTextParser from "../../../utils/richtext-parser";
import { render } from "storyblok-rich-text-react-renderer";
import convertHtml from "../../../utils/convert-html";

const TextItem = styled.div`
  max-width: 960px;
  margin: 0 auto;
  padding: 0 1.0875rem 1.45rem;
  transition: 400ms;
  width: 100%;
  a {
    text-decoration: none;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
    padding: 0;
  }
`;

function StoryblokTextAdapter({ text }) {
  return <TextItem>{render(text)}</TextItem>;
}

export default StoryblokTextAdapter;
