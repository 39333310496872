import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

import PricingBlockCard from "./pricingBlockCard";

const PricingBlockOuter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 980px) {
    flex-wrap: wrap;
  }
`;

const benefits = [
  "Access to exclusive regular email updates",
  "Access to exclusive member surveys",
  "Access to exclusive Trust events",
  "A say in how the club is run",
];

function PricingBlock() {
  return (
    <PricingBlockOuter>
      <PricingBlockCard
        ctaText={"Join"}
        ctaLink={"https://membership.lufctrust.com/join"}
        pricingFrequency={"Yearly Payments"}
        ctaMode={"active"}
        ctaBgColor={"#f2e858"}
        ctaBgColorHover={"#f2e858"}
        ctaColor={"#363244"}
        ctaPadding={"17px"}
        title={"Yearly"}
        price={"10"}
        benefits={benefits}
      />
      <PricingBlockCard
        ctaText={"Join"}
        ctaLink={"https://membership.lufctrust.com/join-lifetime"}
        ctaMode={"active"}
        pricingFrequency={"One Time Payment"}
        ctaBgColor={"#f2e858"}
        ctaBgColorHover={"#f2e858"}
        ctaColor={"#363244"}
        ctaPadding={"17px"}
        title={"Lifetime"}
        price={"100"}
        benefits={benefits}
      />
    </PricingBlockOuter>
  );
}

export default PricingBlock;
