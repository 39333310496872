import React from "react";
import styled from "styled-components";

const ShopProductContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const ProductImageContainer = styled.div`
  background-color: #eceff2;
  height: 340px;
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TextContainer = styled.div``;

const NavigationText = styled.div`
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #939393;
  margin-top: 12px;
`;

const Title = styled.div`
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #414141;
  margin-top: 18px;
  cursor: pointer;
`;

const Price = styled.div`
  font-size: 11px;
  line-height: 15px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  color: #323232;
`;

const Sizes = styled.div`
  font-size: 10px;
  line-height: 14px;
  display: flex;
  align-items: center;
  margin-top: 25px;
  color: #a1a1a1;
`;

const ProductImage = styled.img`
  height: 80%;
`;

function ShopProduct({ image, navigationText, title, price, sizes }) {
  return (
    <ShopProductContainer>
      <ProductImageContainer>
        <ProductImage src={image} />
      </ProductImageContainer>
      <TextContainer>
        <NavigationText>{navigationText}</NavigationText>
        <Title>{title}</Title>
        <Price>{price}</Price>
        <Sizes>{sizes} sizes</Sizes>
      </TextContainer>
    </ShopProductContainer>
  );
}

export default ShopProduct;
