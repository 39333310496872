import React from "react";
import styled from "styled-components";

import CtaButton from "./ctaButton";

const ThankYouContainer = styled.div`
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(${(props) => props.background});
  background-position: bottom;
  background-position-y: -250px;
  background-size: cover;
  height: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  @media (max-width: 1050px) {
    background-position-y: unset;
    background-position: bottom;
  }
`;

const TextContainer = styled.div`
  max-width: 918px;
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 42px;
  line-height: 46px;
  letter-spacing: -1px;
  @media (max-width: 978px) {
    font-size: 32px;
    line-height: 36px;
  }
`;

const Subtitle = styled.div`
  font-weight: 500;
  font-size: 17px;
  line-height: 28px;
  margin-top: 15px;
`;

const CtaButtonContainer = styled.div`
  width: 150px;
  margin-top: 25px;
`;

function ThankYouAd({ title, subtitle, ctaText, ctaLink, backgroundImage }) {
  return (
    <ThankYouContainer background={backgroundImage.filename}>
      <TextContainer>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        <CtaButtonContainer>
          <CtaButton
            text={ctaText}
            link={ctaLink.url}
            backgroundColor={"#2D3445"}
            backgroundColorHover={"#2D3445"}
            color={"#FFFFFF"}
            target={"_blank"}
          />
        </CtaButtonContainer>
      </TextContainer>
    </ThankYouContainer>
  );
}

export default ThankYouAd;
