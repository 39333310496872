import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import useInView from "react-cool-inview";
import { useStaticQuery, graphql } from "gatsby";
import { Parallax } from "react-scroll-parallax";

import FeaturedArticleBlock from "../featuredArticleBlock";
import RootPageArticleLink from "../rootPageArticleLink";
import BlogControls from "../blogControls";
import { getFluidGatsbyImage } from "../../utils/gatsby-storyblok-image-fixed";

const StoryblokArticleRootPageContainer = styled.div`
  width: 100%;
  padding: 0px 50px;
  min-height: calc(100vh - 450px);
  margin-top: -40px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-bottom: 50px;
  position: relative;
  background-color: #fdf8f8;

  @media (max-width: 768px) {
    padding: 0px 20px;
    padding-bottom: 50px;
  }
`;

const StoryblokArticleRootPageContainerInner = styled.div`
  width: 100%;
  max-width: 1300px;
  min-height: 50vh;
`;

const FeaturedArticleBlockContainerSpacer = styled.div`
  width: 100%;
  height: 0px;
`;

const FeaturedArticleBlockContainerOuter = styled.div`
  padding-top: 0px;
  top: 0;
  width: 100%;
  min-height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  left: 0;
  @media (max-width: 768px) {
    padding-top: 0;
    margin-top: 10px;
  }
`;

const FeaturedArticleBlockBackground = styled(motion.div)`
  width: 100%;
  background-color: #f4f6fb;
  margin-top: -150px;
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 100px;
  height: 500px;
`;

const FeaturedArticleBlockContainerInner = styled.div`
  width: 100%;
`;

const ArticleContainer = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(390px, 1fr));
  grid-gap: 1rem;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ArticleItem = styled(motion.div)``;

const LoadingOuter = styled.div`
  height: 50vh;
  width: 100%;
`;

const Loading = styled(motion.div)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2c3445;
  font-weight: bold;
  font-size: 18px;
`;

const NoPosts = styled.div`
  span {
    font-weight: bold;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Button = styled.button`
  background-color: #2c3445;
  color: #fde116;
  border-style: solid;
  border-width: 0px;
  padding: 12px 24px;
  cursor: pointer;
  z-index: 5;
`;

const FeaturedBackgroundAnim = {
  hidden: {
    height: "0%",
  },
  visible: {
    height: "500px",
    transition: {
      delay: 0.4,
    },
  },
};

const ArticleItemAnim = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.2,
    },
  },
};

const LoadingAnim = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 1.1,
    },
  },
};

function StoryblokArticleRootPage({ featuredArticle }) {
  const data = useStaticQuery(graphql`
    query Articles {
      articles: allStoryblokEntry(
        filter: { field_component: { eq: "article" } }
        sort: { fields: [field_dateWritten_string], order: DESC }
      ) {
        edges {
          node {
            name
            full_slug
            field_dateWritten_string
            content
          }
        }
      }
    }
  `);

  const [isInView, setIsInView] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [articles, setArticles] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState();
  const [showFilteredPosts, setShowFilteredPosts] = useState(false);
  const [animationDelay, setAnimationDelay] = useState(1.2);
  const [articlePosition, setArticlePosition] = useState(0);
  const [articleNextPosition, setArticleNextPosition] = useState(8);
  const [updateArticles, setUpdateArticles] = useState(true);
  const [loadingArticles, setLoadingArticles] = useState(true);
  const [allLoaded, setAllLoaded] = useState(false);
  const [darkPatternLoadTime, setDarkPatternLoadTime] = useState(0); //Mwuahahahaha
  const [playAnim, setPlayAnim] = useState(false);
  const [visible, setVisible] = useState(false);
  const [activeCategory, setActiveCategory] = useState("all");
  const [firstTimeAnim, setFirstTimeAnim] = useState(true);

  const [inView, setInview] = useState(true);

  const ArticlesAnim = {
    hidden: {
      opacity: 1,
    },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: animationDelay,
      },
    },
  };

  useEffect(() => {
    if (!allLoaded) {
      if (articles.length === data.articles.edges.length) {
        setAllLoaded(true);
        setVisible(true);
        setLoadingArticles(false);
      } else {
        if (updateArticles) {
          setLoadingArticles(true);
          setTimeout(() => {
            let articlesTemp = data.articles.edges;
            let newArticles = articlesTemp.slice(
              articlePosition,
              articleNextPosition
            );
            let total = articles.concat(newArticles);
            setArticles(total);
            setUpdateArticles(false);
            let newPosition = articlePosition + 8;
            let newNextPosition = articleNextPosition + 8;
            setArticlePosition(newPosition);
            setArticleNextPosition(newNextPosition);
            setLoadingArticles(false);
            setDarkPatternLoadTime(500);
            setTimeout(() => {
              if (!firstTimeAnim) {
                setPlayAnim(false);
              } else {
                setFirstTimeAnim(false);
              }
            }, 100);
          }, darkPatternLoadTime);
        }
      }
    }
  }, [updateArticles]);

  useEffect(() => {
    if (searchInput !== "") {
      const filtered = articles.filter((v) => {
        let match = false;

        if (searchInput) {
          match = v.node.name.toLowerCase().includes(searchInput.toLowerCase());
        }

        return match;
      });

      setFilteredPosts(filtered);

      setShowFilteredPosts(true);
    } else {
      setShowFilteredPosts(false);
    }
  }, [searchInput]);

  useEffect(() => {
    setTimeout(() => {
      setAnimationDelay(0.1);
      setIsInView(true);
    }, 1250);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", isScrolling);
    return () => window.removeEventListener("scroll", isScrolling);
  }, []);

  function isScrolling() {
    /*if (
      window.innerHeight + document.documentElement.scrollTop !==
      document.documentElement.offsetHeight
    ) {
      return;
    } else {
      setUpdateArticles(true);
    }*/
  }
  return (
    <StoryblokArticleRootPageContainer>
      <StoryblokArticleRootPageContainerInner>
        <FeaturedArticleBlockContainerOuter>
          <FeaturedArticleBlockBackground
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            variants={FeaturedBackgroundAnim}
          />

          <FeaturedArticleBlockContainerInner>
            <FeaturedArticleBlock
              title={"Hello World"}
              articleData={featuredArticle}
            />
          </FeaturedArticleBlockContainerInner>
        </FeaturedArticleBlockContainerOuter>
        <FeaturedArticleBlockContainerSpacer />
        <BlogControls
          setSearchInput={setSearchInput}
          setActiveCategory={setActiveCategory}
        />

        {!showFilteredPosts ? (
          <>
            <ArticleContainer
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              variants={ArticlesAnim}
            >
              {articles.map((article, i) => {
                let articleData = JSON.parse(article.node.content);
                let articleImage = getFluidGatsbyImage(
                  articleData.thumbnailImage,
                  {
                    maxWidth: 800,
                  }
                );
                return (
                  <ArticleItem variants={ArticleItemAnim} key={i}>
                    <RootPageArticleLink
                      link={"/" + article.node.full_slug}
                      thumbnail={articleImage}
                      postDate={articleData.dateWritten}
                      title={article.node.name}
                      snippet={articleData.postSnippet}
                    />
                  </ArticleItem>
                );
              })}
            </ArticleContainer>
            {!allLoaded && !loadingArticles && (
              <ButtonContainer>
                <Button onClick={() => setUpdateArticles(true)}>
                  Show More
                </Button>
              </ButtonContainer>
            )}
          </>
        ) : (
          <ArticleContainer
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={ArticlesAnim}
          >
            {filteredPosts.length === 0 && (
              <NoPosts>
                Sorry, there are no results for <span>{searchInput}</span>.
                Plaese try more general keywords.
              </NoPosts>
            )}
            <>
              {filteredPosts.map((article, i) => {
                let articleData = JSON.parse(article.node.content);
                let articleImage = getFluidGatsbyImage(
                  articleData.thumbnailImage,
                  {
                    maxWidth: 800,
                  }
                );
                return (
                  <ArticleItem variants={ArticleItemAnim} key={i}>
                    <RootPageArticleLink
                      link={"/" + article.node.full_slug}
                      thumbnail={articleImage}
                      postDate={articleData.dateWritten}
                      title={article.node.name}
                      snippet={articleData.postSnippet}
                    />
                  </ArticleItem>
                );
              })}
            </>
          </ArticleContainer>
        )}
        {loadingArticles && (
          <LoadingOuter>
            <Loading
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              variants={LoadingAnim}
            >
              Loading...
            </Loading>
          </LoadingOuter>
        )}
      </StoryblokArticleRootPageContainerInner>
    </StoryblokArticleRootPageContainer>
  );
}

export default StoryblokArticleRootPage;
